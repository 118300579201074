import 'swiper/css';
import 'swiper/css/navigation';

// import dateFormat from 'dateformat';
import { React, useState, useEffect, } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from "react-router-dom";
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { ReactComponent as Feature01 } from '../../../assets/icons/homescreen/feature-01.svg';
import { ReactComponent as Feature02 } from '../../../assets/icons/homescreen/feature-02.svg';
import { ReactComponent as Feature03 } from '../../../assets/icons/homescreen/feature-03.svg';
// import { ReactComponent as AcIcon } from '../../../assets/icons/homescreen/ac-repair.svg';
// import { ReactComponent as BathRoomCleanIcon } from '../../../assets/icons/homescreen/bathroom-cleaning.svg';
// import { ReactComponent as CalenderIcon } from "../../../assets/icons/homescreen/calender-ixon.svg";
// import { ReactComponent as FarmpleteIcon } from "../../../assets/icons/homescreen/farmplete-icon.svg";
// import { ReactComponent as FridgeIcon } from '../../../assets/icons/homescreen/fried-repair.svg';
// import { ReactComponent as HomeCleaningIcon } from '../../../assets/icons/homescreen/home-cleaning.svg';
// import { ReactComponent as HomePaintindIcon } from '../../../assets/icons/homescreen/home-painting.svg';
// import { ReactComponent as IntegratedIcon } from '../../../assets/icons/homescreen/integrated-pest.svg';
// import { ReactComponent as MarbleIcon } from '../../../assets/icons/homescreen/marble-polish.svg';
// import { ReactComponent as PestControlIcon } from '../../../assets/icons/homescreen/pest-control.svg';
// import { ReactComponent as SanitizationIcon } from '../../../assets/icons/homescreen/sanitization.svg';
// import { ReactComponent as WashingmachIcon } from '../../../assets/icons/homescreen/washingmachine-repair.svg';
import { ReactComponent as RatingIcon } from '../../../assets/icons/homescreen/star-fill.svg';
// import { ReactComponent as UserIcon } from "../../../assets/icons/homescreen/user-icon.svg";
import Acrepair from '../../../assets/images/ac-repair.png';
import ReviewAuthorImage from '../../../assets/images/author-img1.png';
import ReviewAuthorImage1 from '../../../assets/images/author-img2.png';
import ReviewAuthorImage2 from '../../../assets/images/author-img3.png';
import Carpenter from '../../../assets/images/carpenter.png';
// import CarpetCleaning2 from "../../../assets/images/carpet-cleaning-2.png";
// import CarpetCleaning from "../../../assets/images/carpet-cleaning.png";
import Cleaning from '../../../assets/images/cleaning.png';
import Contracter from '../../../assets/images/contracter.png';
import Electrian from '../../../assets/images/electrian.png';
// import BlogImage from '../../../assets/images/homescreen/blog.png';
// import MiniService from "../../../assets/images/mini-service.png";
import Painting from '../../../assets/images/painting.png';
import PlaystoreImage from '../../../assets/images/playstore-img.png';
import Plumber from "../../../assets/images/plumber.png";
import RoadWork from '../../../assets/images/road-worker.png';
// import SofaCleaning from "../../../assets/images/sofa-cleaning.png";
// import SuperSaver from "../../../assets/images/super-saver.png";
import OwnerImg from '../../../assets/images/usertypes/client.png';
import EnterrpiseImg from '../../../assets/images/usertypes/enterprise.png';
import ProviderImg from '../../../assets/images/usertypes/provider.png';
import LoginDialogBody from '../../../dialogs/alertdialog/alertdialog_login';
import Footer from '../../../global/components/footer';
// import OutlinedButton from '../components/outlinedbutton';
import * as AlertDialog from "../../../redux/slices/alertdialog";


// Create an array of Services data objects
const serviceItems = [
    {
        title: "AC Repair",
        image: Acrepair,
        altText: "home-cleaing-image",
        url: "/",
    },
    {
        title: "Electrician",
        image: Electrian,
        altText: "home-cleaing-image",
        url: "/",
    },
    {
        title: "Plumber",
        image: Plumber,
        altText: "home-cleaing-image",
        url: "/",
    },
    {
        title: "Road Worker",
        image: RoadWork,
        altText: "home-cleaing-image",
        url: "/",
    },
    {
        title: "Painting",
        image: Painting,
        altText: "home-cleaing-image",
        url: "/",
    },
    {
        title: "Cleaning",
        image: Cleaning,
        altText: "home-cleaing-image",
        url: "/",
    },
    {
        title: "Carpenter",
        image: Carpenter,
        altText: "home-cleaing-image",
        url: "/",
    },
    {
        title: "Contracter",
        image: Contracter,
        altText: "home-cleaing-image",
        url: "/",
    },
];

const reviews = [
    {
        "review": "I recently hired Amazing Cleaning Services to tackle a big cleaning project at my home, and I must say I was thoroughly impressed with their professionalism and attention to detail. The team arrived on time, equipped with all the necessary tools and cleaning supplies. They worked diligently and efficiently, ensuring that every nook and cranny was spotless by the time they were done. Not only did they meet my expectations, but they exceeded them. I highly recommend their services to anyone in need of top-quality cleaning!",
        "name": "John Doe",
        "date": Date.now(),
        "reviewStars": 5,
        "image": ReviewAuthorImage2,
        "serviceName": "Amazing Cleaning Services"
    },
    {
        "review": "I recently had the pleasure of working with Professional Painting Solutions, and I couldn't be happier with the results. From the initial consultation to the final coat of paint, the entire process was smooth and hassle-free. The team was incredibly skilled and attentive to detail, ensuring that every stroke of the brush was perfect. Not only did they transform the look of my home, but they also completed the project ahead of schedule. I would definitely recommend Professional Painting Solutions to anyone looking for high-quality painting services.",
        "name": "Jane Smith",
        "date": Date.now(),
        "reviewStars": 4,
        "image": ReviewAuthorImage,
        "serviceName": "Professional Painting Solutions"
    },
    {
        "review": "I recently had the pleasure of working with Reliable Plumbing Services for a plumbing issue in my home, and I must say I was impressed with their prompt response and excellent service. The plumber arrived on time and quickly diagnosed the problem. He explained the issue clearly and provided me with a range of solutions to choose from. Throughout the repair process, he was professional, courteous, and efficient. I was very satisfied with the quality of workmanship and the fair pricing. I highly recommend Reliable Plumbing Services to anyone in need of plumbing assistance.",
        "name": "Alice Johnson",
        "date": Date.now(),
        "reviewStars": 3,
        "image": ReviewAuthorImage2,
        "serviceName": "Reliable Plumbing Services"
    },
    {
        "review": "I recently hired Top-Notch Roofing Solutions to repair a leak in my roof, and I am extremely satisfied with the service I received. From the moment I contacted them, they were responsive and attentive to my needs. The roofing team arrived on time and worked diligently to identify and fix the problem. They were skilled, efficient, and respectful of my property. The quality of their workmanship was top-notch, and they completed the job in a timely manner. I would highly recommend Top-Notch Roofing Solutions to anyone in need of roofing repairs or installations.",
        "name": "Michael Brown",
        "date": Date.now(),
        "reviewStars": 5,
        "image": ReviewAuthorImage1,
        "serviceName": "Top-Notch Roofing Solutions"
    },
    {
        "review": "I recently had the pleasure of working with Efficient Electrical Repairs to fix some electrical issues in my home, and I was very impressed with their service. The electrician arrived on time and was friendly, professional, and knowledgeable. He quickly identified the problem and explained the repair process to me in detail. Throughout the job, he was thorough and efficient, ensuring that everything was working perfectly before he left. I was very satisfied with the quality of work and the reasonable pricing. I would definitely recommend Efficient Electrical Repairs to anyone in need of electrical services.",
        "name": "Emily Wilson",
        "date": Date.now(),
        "reviewStars": 4,
        "image": ReviewAuthorImage,
        "serviceName": "Efficient Electrical Repairs"
    }
];

// const blog = {
//     "title": "Transform Your Terrace or Balcony Tips for a Better Home",
//     "description": "Most Indian households take their terrace or a balcony for granted while being unaware of the various things we can transform them into! Let's see how It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages.",
//     "date": Date.now(),

// };

// const services = [
//     {
//         "image": SofaCleaning,
//         "title": 'Sofa Cleaning',
//         "stars": "4.56",
//         "reviewCount": "126.7k",
//         "price": 129,
//     },
//     {
//         "image": CarpetCleaning,
//         "title": 'Carpet Cleaning',
//         "stars": "4.78",
//         "reviewCount": "126.7k",
//         "price": 49,
//     },
//     {
//         "image": SofaCleaning,
//         "title": 'Sofa Cleaning',
//         "stars": "4.56",
//         "reviewCount": "126.7k",
//         "price": 129,
//     },
//     {
//         "image": MiniService,
//         "title": 'Mini Services',
//         "stars": "4.89",
//         "reviewCount": "126.7k",
//         "price": 99,
//     },
//     {
//         "image": SuperSaver,
//         "title": 'Super Saver Deals',
//         "stars": "4.52",
//         "reviewCount": "126.7k",
//         "price": 59,
//     },
//     {
//         "image": CarpetCleaning2,
//         "title": 'Carpet Cleaning',
//         "stars": "4.83",
//         "reviewCount": "126.7k",
//         "price": 79,
//     },
//     {
//         "image": MiniService,
//         "title": 'Mini Services',
//         "stars": "4.89",
//         "reviewCount": "126.7k",
//         "price": 99,
//     },
// ];

const featureItems = [
    {
        title: "Vetted Pros for peace of mind.",
        image: <Feature01 className="h-100 sm:w-full w-[90%]" />,
        altText: "home-cleaing-image",
    },

    {
        title: "Verified homeowners and customers",
        image: <Feature02 className="h-100 sm:w-full w-[90%]" />,
        altText: "home-cleaing-image",
    },

    {
        title: "Work with real people, no ghost leads",
        image: <Feature03 className="h-100 sm:w-full w-[90%]" />,
        altText: "home-cleaing-image",
    },
];

const faqItems = [
    { question: 'What services do you provide?', answer: 'We offer a wide range of handyman services including plumbing, electrical repairs, carpentry, painting, appliance installation, and general home maintenance. If you have a specific request, feel free to ask!' },
    { question: 'How do I book a handyman?', answer: 'You can book a handyman through our website or app by selecting the service you need and scheduling a time that works for you.' },
    { question: 'Do you offer emergency services?', answer: 'Yes, we offer emergency services for urgent repairs. Contact our support for immediate assistance.' },
    { question: 'How do I pay for the service?', answer: 'Payments can be made through the app or website using a credit card, debit card, or other available payment methods.' },
    { question: 'How do I get jobs through the app?', answer: 'To get jobs, register as a service provider, complete your profile, and start receiving job requests from customers.' },
    { question: 'How are rates determined?', answer: 'Rates are based on the type of service, duration, and complexity of the job. A detailed breakdown is provided during booking.' },
    { question: 'How do I handle disputes or complaints?', answer: 'For disputes or complaints, contact our support team. We aim to resolve issues fairly and promptly.' },
];



export default function Home() {
    // const isMobileView = window.innerWidth < 640;

    const dispatcher = useDispatch();
    const onLogin = () => {
        dispatcher(AlertDialog.show({ child: <LoginDialogBody /> }));
    };

    useEffect(() => {
        Notification.requestPermission();
    }, []);

    const [activeIndex, setActiveIndex] = useState(0); // First item is open by default


    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const [activeTab, setActiveTab] = useState("Enterprise");

    const tabs = ["Enterprise", "Homeowner", "Professional"];


    // Section linking
    const { hash } = useLocation();

    useEffect(() => {
        if (hash) {
            const element = document.getElementById(hash.replace("#", ""));
            if (element) {
                element.scrollIntoView({ behavior: "smooth" });
            }
        }
    }, [hash]);


    return (
        <>
            <main>
                <section className='hero-banner'>
                    <div className="grid grid-cols-3 max-md:grid-cols-1">
                        <div className="image-container h-[740px] max-md:h-[400px] flex items-end justify-center">
                            <img loading='lazy' className='max-md:object-top' src={OwnerImg} alt="Residential" />
                            <h2 className='text-center text-white py-6 font-bold xl:text-5xl text-4xl uppercase leading-normal grid mb-[60px]'><span className='xl:text-3xl text-2xl font-light'></span>Residential <span className='w-[100px] h-[4px] bg-white mt-4 mx-auto'></span></h2>

                        </div>
                        <div className="image-container h-[740px] max-md:h-[400px] flex items-end justify-center divider-left-custom">
                            <img loading='lazy' className='max-md:object-top' src={ProviderImg} alt="Professional" />
                            <h2 className='text-center text-white py-6 font-bold xl:text-5xl text-4xl uppercase leading-normal grid mb-[60px]'><span className='xl:text-3xl text-2xl font-light'></span>Professional <span className='w-[100px] h-[4px] bg-white mt-4 mx-auto'></span></h2>

                        </div>
                        <div className="image-container h-[740px] max-md:h-[400px] flex items-end justify-center divider-left-custom">
                            <img loading='lazy' className='max-md:object-top' src={EnterrpiseImg} alt="Commercial" />
                            <h2 className='text-center text-white py-6 font-bold xl:text-5xl text-4xl uppercase leading-normal grid mb-[60px]'><span className='xl:text-3xl text-2xl font-light'></span>Commercial <span className='w-[100px] h-[4px] bg-white mt-4 mx-auto'></span></h2>

                        </div>
                    </div>
                </section>

                <section id='categories' className='bg-white md:py-28 py-14' >
                    <div className="container mx-auto">
                        <h2 className='text-center md:text-5xl text-4xl uppercase font-light leading-normal text-black'><span className='font-bold'>Categories</span></h2>
                        <div className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 place-items-start gap-5 md:mt-6 md:pt-8 mt-2 pt-4">
                            {serviceItems.map((service, index) => (
                                <div className="w-full" key={index}>
                                    <Link className='bg-white cursor-pointer relative flex justify-center items-end w-full aspect-video h-auto rounded-primary overflow-hidden' to={service.url}>
                                        <img loading="lazy" src={service.image} alt="service" className="absolute object-cover text-center w-[100%] h-[100%]" />
                                        {/* <div className="text-center w-[64px] h-[64px] mx-auto">
                                            {service.image}
                                        </div> */}
                                        <div className="w-[100%] h-[100%] absolute" style={{ background: "linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 100%)" }} />
                                        <div className="flex flex-col items-center z-[1] mb-3">
                                            <h4 className="text-white font-bold text-xl capitalize text-center">{service.title}</h4>
                                        </div>
                                    </Link>
                                </div>
                                //     <div className="bg-white hover:border-primary transition-all duration-200 ease-in-out flex items-center justify-center border border-Gainsboro rounded-secondary p-7 text-center shadow-[0_10px_10px_0px_rgba(0,0,0,0.03)]" key={index}>
                                //     <Link to={service.url}>
                                //         <div className="text-center w-[64px] h-[64px] mx-auto">
                                //             {service.image}
                                //         </div>
                                //         <h4 className="mt-7 text-darkGray font-bold text-xl capitalize">{service.title}</h4>
                                //     </Link>
                                // </div>
                            ))}
                        </div>
                        <button type="button" className="text-white mx-auto flex items-center justify-center relative rounded-[12px] primary-gradient transition ease-in-out min-w-[160px] h-[46px] p-1 uppercase mt-10">
                            show all
                        </button>
                    </div>
                </section>

                <section id='reviews' className='bg-wildSand md:py-28 py-14' >
                    <div className="container mx-auto">
                        <div className="flex md:gap-[0px] gap-[20px] justify-between flex-wrap">
                            <div className="md:w-1/2 w-full">
                                <h2 className='md:text-start md:text-5xl text-center text-4xl uppercase font-light leading-normal text-darkGray'>Customer <span className='font-bold'>Reviews</span></h2>
                            </div>
                            <div className="md:w-1/2 w-full flex md:justify-end justify-center items-center">
                                <div className="min-w-[120px] relative flex items-center justify-between reviewNavigation" id='reviewNavigation'>
                                    <div className="swiper-button-prev bg-white border border-Gray78 rounded-[10px]"></div>
                                    <div className="swiper-button-next bg-white border border-Gray78 rounded-[10px]"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='pt-14 max-sm:pt-4 reviewslider'>
                        <Swiper
                            modules={[Navigation]}
                            spaceBetween={10}
                            // centeredSlides={!isMobileView}
                            slidesPerView={1}
                            breakpoints={{
                                750: {
                                    slidesPerView: 2,
                                    slidesOffsetBefore: 0,
                                },
                                1100: {
                                    slidesPerView: 3,
                                    slidesOffsetBefore: 0,
                                },
                                1400: {
                                    slidesPerView: 4.5,
                                    slidesOffsetBefore: 0,
                                },
                            }}
                            loop={true}
                            navigation={{
                                nextEl: '#reviewNavigation .swiper-button-next',
                                prevEl: '#reviewNavigation .swiper-button-prev'
                            }}
                        >
                            {
                                reviews.map((review) => {
                                    const items = [];
                                    for (let i = 0; i < review['reviewStars']; i++) {
                                        items.push(<RatingIcon key={i} />);
                                    }

                                    return (
                                        <SwiperSlide key={review.uuid} className='p-3 slider-item h-100'>
                                            <div className="shadow-[0_5px_15px_0px_rgba(0,0,0,0.04)] rounded-[8px] p-7 text-center h-full bg-white border-Gainsboro border-[1px]">
                                                <p className='text-base font-normal font-darkBrown mb-6'>{`“${review["review"]}”`}</p>
                                                <div className="flex gap-[12px]">
                                                    <div>
                                                        <div className='w-[82px] min-h-[82px] mx-auto'>
                                                            <img loading='lazy' src={review['image']} alt="Author Image" className='w-full h-full object-cover rounded-[10px]' />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <h1 className='text-xl font-semibold text-left'>{review["name"]}</h1>
                                                        <div className='flex flex-row justify-center items-center gap-5 mb-2 mt-1 max-lg:flex-col max-lg:gap-1'>
                                                            {/* <div className='flex flex-row items-center gap-1'>
                                                                <CalenderIcon />
                                                                <h1>{dateFormat(review["date"], "d mmm, yyyy")}</h1>
                                                            </div> */}
                                                            <div className='flex flex-row items-center gap-1'>
                                                                {/* <UserIcon /> */}
                                                                <span>{review["serviceName"]}</span>
                                                            </div>
                                                        </div>
                                                        <div className="flex flex-row gap-1 w-[100%] justify-start">
                                                            {items}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    );
                                })
                            }

                        </Swiper>
                    </div>
                </section>

                {/* <section className='overflow-hidden'>
                    <div className='grid lg:grid-cols-2 items-start mb-20 justify-stretch'>
                        <div className='h-full lg:max-h-full max-h-[450px] '>
                            <img loading='lazy' className='object-cover lg:h-[90%]' width="100%" height="100%" src={BlogImage} alt='blog image' />
                        </div>
                        <div className='relative flex flex-col justify-between bg-primary h-[100%] p-24'>
                            <div className='absolute right-0 xl:h-[100%] max-lg:h-[80%]'>
                                <FarmpleteIcon className='opacity-[0.12] h-[100%] w-[100%] object-cover' />
                            </div>

                            <h1 className='font-light text-white text-5xl'>OUR</h1>
                            <h1 className='font-semibold text-white text-5xl'>LATEST BLOG</h1>

                            <div className='mt-10'>
                                <h1 className='font-semibold text-white text-2xl'>{blog["title"]}</h1>
                            </div>

                            <div className='my-6 flex flex-row items-center gap-3'>
                                <CalenderIcon className='filter-white' />
                                <h1 className='text-white'>{dateFormat(blog["date"], "d mmm, yyyy")}</h1>
                            </div>

                            <div className='w-[100px] h-1 rounded-primary bg-white'></div>

                            <div className='mt-10 xl:mr-20'>
                                <p className='text-white text-lg font-normal'>{blog["description"]}</p>
                            </div>

                            <div className='mt-10'>
                                <OutlinedButton normal="white" extracss="border-white p-3 px-5 text-white hover:text-primary hover:bg-white" focused="primary" label="VIEW MORE" />
                            </div>
                        </div>
                    </div>
                </section> */}

                {/* <section>
                    <div className="container mx-auto mb-10">
                        <div className="flex">
                            <div className="md:w-1/2">
                                <h2 className='text-start md:text-5xl text-4xl font-light leading-normal text-darkGray'>OUR <span className='font-bold'>PRICING</span></h2>
                            </div>
                            <div className="md:w-1/2 flex justify-end items-center">
                                <div className="min-w-[120px] relative flex items-center justify-between reviewNavigation" id='reviewNavigation'>
                                    <div className="swiper-button-prev border border-Gray78 rounded-[10px]"></div>
                                    <div className="swiper-button-next border border-Gray78 rounded-[10px]"></div>
                                </div>
                            </div>
                        </div>
                        <div className='py-5 serviceSlider'>
                            <Swiper
                                modules={[Navigation]}
                                spaceBetween={20}
                                breakpoints={{
                                    1200: {
                                        slidesPerView: 5,
                                    },
                                    1024: {
                                        slidesPerView: 4,
                                    },
                                    900: {
                                        slidesPerView: 3,
                                    },
                                    600: {
                                        slidesPerView: 2,
                                    },
                                }}
                                loop
                                navigation={{
                                    nextEl: '#serviceNavigation .swiper-button-next',
                                    prevEl: '#serviceNavigation .swiper-button-prev'
                                }}
                            >
                                {
                                    services.map((service) => {
                                        return (
                                            <SwiperSlide key={service.uuid} className='p-3 service-slider-item w-[100%]'>
                                                <div className="rounded-primary text-start justify-start flex flex-col">
                                                    <div>
                                                        <div className='w-[100%] mx-auto'>
                                                            <img loading='lazy' src={service['image']} alt="Author Image" className='w-full h-full object-cover rounded-[10px]' />
                                                        </div>

                                                        <h1 className='text-xl font-semibold pt-2 line-clamp-1'>{service["title"]}</h1>

                                                        <div className='flex flex-row items-center gap-1'>
                                                            <RatingIcon className='filter-grey h-[12px]' />
                                                            <h4 className='text-darkGray font-medium text-xs text-opacity-60'>{`${service['stars']}   (${service['reviewCount']})`}</h4>
                                                        </div>
                                                    </div>
                                                    <div className='mt-2'>
                                                        <OutlinedButton extracss="flex px-4 py-2 text-primary border-primary hover:bg-primary hover:text-white" label={`$${service["price"]}`} />
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        );
                                    })
                                }
                            </Swiper>
                        </div>
                    </div>
                </section> */}

                <section id='pricing' className='bg-white md:py-28 py-14'>
                    <div className="container mx-auto">
                        <h2 className='text-center md:text-5xl text-4xl uppercase font-light leading-normal text-black'><span className='font-bold'>pricing</span></h2>

                        <div className='pt-5'>
                            <div className="w-full py-8">
                                {/* Tabs */}
                                <div className="max-w-2xl mx-auto border border-Gainsboro rounded-[8px] overflow-hidden p-[10px]">
                                    {/* Background Slide */}
                                    <div className='relative flex items-center justify-center flex-wrap'>
                                        <div
                                            className="absolute top-0 left-0 w-1/3 h-full primary-gradient transition-transform duration-300 ease-in-out rounded-[8px]"
                                            style={{
                                                transform: `translateX(${tabs.indexOf(activeTab) * 100}%)`,
                                            }}
                                        />
                                        {tabs.map((tab) => (
                                            <button
                                                key={tab}
                                                onClick={() => setActiveTab(tab)}
                                                className={`w-1/3 text-center p-3 font-bold text-base uppercase relative z-10 max-md:text-sm truncate ${activeTab === tab
                                                    ? "text-white"
                                                    : "text-black"
                                                    }`}
                                            >
                                                {tab}
                                            </button>
                                        ))}
                                    </div>
                                </div>

                                {/* Tab Content */}
                                <div className="bg-wildSand mt-8 grid grid-cols-3 max-md:grid-cols-1 gap-4 border border-Gainsboro rounded-[45px] p-[35px] max-md:p-[20px] max-md:rounded-[20px]">
                                    {activeTab === "Enterprise" && (
                                        <>
                                            <div className="bg-white border border-Gainsboro rounded-[18px] p-[30px]">
                                                <h2 className="font-bold text-2xl text-center mb-3">Pro plan</h2>
                                                <p className="text-center text-xl font-bold text-black my-4"><span className='text-primary text-5xl max-lg:text-4xl'>$99</span>/month</p>
                                                <ul className="space-y-1 text-lg text-black list-disc list-inside font-normal max-sm:text-base">
                                                    <li>Exclusive Access to Resources</li>
                                                    <li>Community Support</li>
                                                    <li>Weekly Newsletters</li>
                                                    <li>Discounts on Tools and Services</li>
                                                    <li>Personalized Feedback</li>
                                                </ul>
                                                <div className='text-center'>
                                                    <button className="mt-[30px] mx-auto inline-flex text-center justify-center items-center primary-gradient rounded-[8px] text-white py-2 px-[30px] text-base uppercase font-bold" onClick={() => onLogin()}>
                                                        Book Now
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="bg-primary border border-Gainsboro rounded-[18px] p-[30px]">
                                                <h2 className="text-white font-bold text-2xl text-center mb-3">Pro Plus Plan</h2>
                                                <p className="text-center text-xl font-bold text-white my-4"><span className='text-white text-5xl max-lg:text-4xl'>$189</span>/year</p>
                                                <ul className="space-y-1 text-lg text-white list-disc list-inside font-normal max-sm:text-base">
                                                    <li>Exclusive Access to Resources</li>
                                                    <li>Community Support</li>
                                                    <li>Weekly Newsletters</li>
                                                    <li>Discounts on Tools and Services</li>
                                                    <li>Personalized Feedback</li>
                                                </ul>
                                                <div className='text-center'>
                                                    <button className="mt-[30px] mx-auto inline-flex text-center justify-center items-center bg-white rounded-[8px] text-primary py-2 px-[30px] text-base uppercase font-bold" onClick={() => onLogin()}>
                                                        Book Now
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="bg-white border border-Gainsboro rounded-[18px] p-[30px]">
                                                <h2 className="font-bold text-2xl text-center mb-3">Pro Elite Plan</h2>
                                                <p className="text-center text-xl font-bold text-black my-4"><span className='text-primary text-5xl max-lg:text-4xl'>$249</span>/year</p>
                                                <ul className="space-y-1 text-lg text-black list-disc list-inside font-normal max-sm:text-base">
                                                    <li>Exclusive Access to Resources</li>
                                                    <li>Community Support</li>
                                                    <li>Weekly Newsletters</li>
                                                    <li>Discounts on Tools and Services</li>
                                                    <li>Personalized Feedback</li>
                                                </ul>
                                                <div className='text-center'>
                                                    <button className="mt-[30px] mx-auto inline-flex text-center justify-center items-center primary-gradient rounded-[8px] text-white py-2 px-[30px] text-base uppercase font-bold" onClick={() => onLogin()}>
                                                        Book Now
                                                    </button>
                                                </div>
                                            </div>
                                        </>
                                    )}

                                    {activeTab === "Homeowner" && (
                                        <div className="col-span-3 text-center">
                                            <p className="text-lg text-gray-600">Homeowner-specific content will be displayed here.</p>
                                        </div>
                                    )}

                                    {activeTab === "Professional" && (
                                        <div className="col-span-3 text-center">
                                            <p className="text-lg text-gray-600">Professional-specific content will be displayed here.</p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id='features' className='bg-wildSand md:py-28 py-14'>
                    <div className="container mx-auto">
                        <h2 className='text-center md:text-5xl text-4xl uppercase font-light leading-normal text-black'>Our <span className='font-bold'>features</span></h2>

                        <div className='pt-5'>
                            <div className="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 place-items-start gap-5 md:mt-6 md:pt-8 mt-2 pt-4">
                                {featureItems.map((feature, index) => (
                                    <div className="w-full" key={index}>
                                        <div className='bg-white relative flex justify-center items-center w-full shadow-[0_5px_15px_0px_rgba(0,0,0,0.04)] rounded-[8px] md:p-7 p-3 text-center h-ful border-Gainsboro border-[1px] z-10'>
                                            <div className="text-center sm:h-[350px] h-full flex items-center justify-center">
                                                {feature.image}
                                            </div>
                                        </div>
                                        <div className='bg-white relative flex justify-center items-center shadow-[0_5px_15px_0px_rgba(0,0,0,0.04)] rounded-[8px] px-[20px] pt-[30px] pb-[20px] text-center h-ful border-Gainsboro border-[1px] w-[85%] mx-auto mt-[-15px] z-0'>
                                            <div className="flex flex-col items-center ">
                                                <h4 className="text-black font-normal md:text-base text-sm capitalize text-center">{feature.title}</h4>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </section>

                <section id='faq' className='bg-white md:py-28 py-14'>
                    <div className="container mx-auto">
                        <h2 className='text-center md:text-5xl text-4xl uppercase font-light leading-normal text-black'><span className='font-bold'>FAQ’s</span></h2>

                        <div className="faq-section max-w-3xl mx-auto mt-8">
                            <div className="bg-gray-100 rounded-b-lg">
                                {faqItems.map((item, index) => (
                                    <div
                                        key={index}
                                        className={` my-2 rounded-[8px] ${activeIndex === index ? 'bg-gray-200' : 'bg-gray-100'
                                            }`}
                                    >
                                        <button
                                            onClick={() => toggleAccordion(index)}
                                            className={`w-full text-left px-6 py-4 flex justify-between items-center transition ease-in-out duration-300 ${activeIndex === index ? 'primary-gradient rounded-tl-[8px] rounded-tr-[8px]' : 'bg-PaleGray rounded-[8px]'
                                                }`}
                                        >
                                            <span className={`text-xl font-normal transition ease-in-out duration-300 ${activeIndex === index ? 'text-white' : 'text-black'}`}>{item.question}</span>
                                            <span className={`text-xl font-bold transition ease-in-out duration-300 ${activeIndex === index ? 'text-white' : 'text-black'}`}>
                                                {activeIndex === index ? '−' : '+'}
                                            </span>
                                        </button>
                                        {activeIndex === index && (
                                            <div className="bg-wildSand rounded-bl-[8px] rounded-br-[8px] px-6 py-4 text-base font-medium text-gray-600 transition ease-in-out duration-300 ">
                                                {item.answer}
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </section>

                <section className='primary-gradient md:py-28 py-14'>
                    <div className="container mx-auto text-center">
                        <h2 className='text-center md:text-5xl text-4xl leading-normal text-white'><span className='font-bold'>Let’s download free from apple and play store</span></h2>

                        <Link className="inline-flex items-center justify-center mt-7 mx-auto">
                            <img src={PlaystoreImage} alt="Play Store" className='max-w-[200px]' />
                        </Link>
                    </div>
                </section>

                <Footer />
            </main >
        </>
    );
}
