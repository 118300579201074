import dateFormat from 'dateformat';
import React from 'react';
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

import { ReactComponent as Calender } from "../../../assets/icons/dashboard/calender.svg";
import { ReactComponent as Location } from "../../../assets/icons/dashboard/location.svg";
import Star from "../../../assets/icons/homescreen/star-fill.svg";
import { domain } from "../../../axios/adapter";
import fetch from "../../../axios/manager";
import * as Loader from "../../../dialogs/loader/loaderop";
import showToast from "../../../dialogs/loader/toast";
import ImageVideoCarousel from '../../../global/components/image-video-carousel';
import VideoContainer from '../../../global/components/videocontainer';
import generateId from "../../../global/methods/generateid";
import { toFormData } from "../../../global/methods/toformdata";
import * as Alertdialog from '../../../redux/slices/alertdialog';
import { addItem, deleteItem, updateItem } from '../../../redux/slices/joblistings';
import { setProgress } from "../../../redux/slices/toploadingbar";
import EstimateContainer from "../../chats/components/estimate_container";
import OutlinedButton from "../../homepage/components/outlinedbutton";
import AddReviewDialog from '../../jobs/screens/addreview';

const CompletedGigScreen = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatcher = useDispatch();

    const { label, gig } = location.state;

    const completedSlice = useSelector((state) => label == "Progress" ? state.jobListingSlice.progress : state.jobListingSlice.completed);

    const mgig = completedSlice[gig.uuid];

    if (mgig == null) {
        return <Navigate to="/dashboard" replace />;
    }

    const userSlice = useSelector((state) => state.userSlice);
    const profile = userSlice.profile;
    const user = userSlice.user;

    const myReview = user.userType == "provider" ? mgig.providerReview : mgig.clientReview;
    const otherReview = user.userType == "provider" ? mgig.clientReview : mgig.providerReview;
    const otherProfile = user.userType == "provider" ? mgig.clientProfile : mgig.providerProfile;

    const estimate = mgig?.estimate;

    const item = mgig?.job ?? mgig?.service;

    const fetchEstimate = async () => {
        if (estimate != null) return;
        dispatcher(setProgress(50));
        Loader.show();

        const [result, error] = await fetch({
            route: `completedGigs/${mgig.uuid}`,
            requestType: "get",
        });

        if (error != null) {
            return onError(error);
        }

        dispatcher(setProgress(100));
        Loader.hide();

        const data = result.res;

        dispatcher(updateItem({
            type: label.toLowerCase(), item: data,
        }));
    };

    function onError(error) {
        showToast(error);
        dispatcher(setProgress(100));
        return Loader.hide();
    }

    useEffect(() => {
        fetchEstimate();
    }, []);

    const onMarkAsPaid = async () => {
        Loader.show();
        dispatcher(setProgress(25));

        const [transactionData, transactionError] = await fetch({
            route: "payments/auth/transactions",
            requestType: "post",
            body: {
                transactionId: generateId(10),
                amount: estimate.price,
                paidToUUID: profile.uuid,
            }
        });

        if (transactionError != null) {
            return onError(transactionError);
        }

        dispatcher(setProgress(50));

        const transaction = transactionData.res;

        const [, gigError] = await fetch({
            route: `completedGigs/auth/${gig.uuid}`,
            requestType: "put",
            body: {
                transactionUUID: transaction.uuid,
            }
        });

        if (gigError != null) {
            return onError(gigError);
        }

        dispatcher(setProgress(100));
        Loader.hide();

        dispatcher(addItem({
            type: "completed",
            item: {
                ...mgig,
                transaction: transaction,
                transactionId: transaction.id,
            },
        }));
        dispatcher(deleteItem({
            type: "progress",
            item: mgig,
        }));

        navigate(-1);
    };

    async function onCreateReview({ review, images, description }) {
        Loader.show();
        dispatcher(setProgress(25));

        const obj = {
            review: description,
            givenStars: review,
            completedGigUUID: mgig.uuid,
        };

        if (images.length != 0) {
            obj.images = images;
        }

        if (mgig?.job == null) {
            obj.serviceUUID = mgig?.service.uuid;
        }
        else {
            obj.jobUUID = mgig?.job.uuid;
        }

        const [reviewResult, reviewError] = await fetch({
            route: "reviews/auth/",
            requestType: "post",
            body: toFormData(obj),
        });

        if (reviewError != null) {
            showToast(reviewError);
            dispatcher(setProgress(100));
            return Loader.hide();
        }

        setProgress(50);

        const reviewObj = reviewResult.res;

        const key = user.userType == "provider" ? "providerReview" : "clientReview";

        const [, completedGigError] = await fetch({
            route: `completedGigs/auth/${mgig.uuid}`,
            requestType: "put",
            body: {
                [`${key}UUID`]: reviewObj.uuid,
            },
        });

        dispatcher(setProgress(75));

        if (completedGigError != null) {
            showToast(completedGigError);
            dispatcher(setProgress(100));
            return Loader.hide();
        }

        dispatcher(updateItem({
            type: "completed", item: {
                ...mgig,
                [key]: reviewObj,
            }
        }));

        Loader.hide();
        dispatcher(setProgress(100));
    }

    function onImageClicked(images) {
        dispatcher(Alertdialog.show({
            child: <ImageVideoCarousel items={[...images.map((image) =>
                <img key={image} className="w-full h-[50rem] object-contain" src={`${domain}/static/${image}`} alt="job" />
            )]} />,
            className: "bg-transparent !shadow-none",
        }));
    }

    return <main>
        <section>
            <div className="flex flex-col w-[100%]">
                <div className="mx-10">
                    <div className="mt-6 w-[100%] xl:px-32 lg:px-20 flex flex-col">
                        <div className="flex flex-row justify-between items-center w-[100%]">
                            <h1 className="text-black font-normal mb-10 text-3xl mt-12">{label} <span className="font-semibold">{mgig.job == null ? "Service" : "Job"}</span></h1>
                        </div>
                        <div className="w-[100%] h-[1px] bg-darkGray bg-opacity-20" />

                        <h1 className="text-black font-medium mb-5 text-3xl mt-12">{item.title}</h1>
                        <p className="text-darkGray opacity-50 w-[80%]">{item.fullDescription}</p>
                        <h1 className="text-darkGray font-semibold mt-5">Images:</h1>

                        <div className="2xl:w-[60%] w-[65%] max-md:w-[75%] gap-4 flex flex-row flex-wrap justify-start pt-3">
                            {item.videos.map((video) => <VideoContainer className="w-[130px] h-[130px]" key={video} url={video} />)}
                            {item.images.map((image) =>
                                <img key={image} onClick={() => onImageClicked(item.images)} className="cursor-pointer rounded-primary w-[130px] h-[130px] object-cover" src={`${domain}/static/${image}`} alt="job" />
                            )}
                        </div>

                        <div className="w-[100%] h-[8px] bg-darkGray bg-opacity-5 my-5" />

                        <div className="px-4 py-4 items-start mb-10 rounded-primary flex flex-col w-[500px]">
                            <div className="flex flex-row items-start justify-between w-[100%] gap-4">
                                <img className="w-[18%] object-cover aspect-square rounded-[50%]" src={`${domain}/static/${profile.profileImage}`} alt="profile" />
                                <div className="flex-grow flex flex-col gap-1 items-start justify-around">
                                    <h1 className="text-black font-semibold text-xl line-clamp-2">{`${profile.firstName} ${profile.lastName}`}</h1>
                                    <div className="flex flex-row w-[100%] justify-start items-start gap-2">
                                        <Location className="h-6 w-6 mt-[1px]" height="100%" width="100%" />
                                        <h1 className="text-lightGray text-sm mr-5 line-clamp-2 text-ellipsis">{`${profile.location}, ${profile.landmark}, ${profile.state}`}</h1>
                                    </div>
                                    <div className="flex flex-row justify-start w-[100%] items-start gap-2">
                                        <Calender className="h-4 w-6 mt-[1px]" height="100%" width="100%" />
                                        <h1 className="text-lightGray text-sm mr-5 line-clamp-1">{dateFormat(item.createdAt, "d mmm, yyyy | h:mm tt")}</h1>
                                    </div>
                                </div>
                            </div>
                            <h1 className="text-black font-semibold text-md capitalize mt-2">Job Location:</h1>
                            <div className="flex flex-row w-[100%] justify-start items-center gap-2 mb-5">
                                <Location className="h-6 w-6 mt-[1px]" height="100%" width="100%" />
                                <h1 className="text-lightGray text-sm mr-5 line-clamp-2 text-ellipsis">{`${profile.location}, ${profile.landmark}, ${profile.state}`}</h1>
                            </div>

                            {estimate != null && <EstimateContainer item={estimate} paymentStatus={mgig.transaction == null ? "pending" : "paid"} />}
                            {user.userType === "provider" && label === "Progress" && <OutlinedButton onClicked={onMarkAsPaid} extracss="w-[100%] h-11 bg-white border-primary border hover:bg-primary text-primary hover:text-white" label={"Mark as Paid"} />}

                            {myReview == null && label !== "Progress" && <OutlinedButton onClicked={() => {
                                dispatcher(Alertdialog.show({ child: <AddReviewDialog onSubmit={onCreateReview} /> }));
                            }} extracss="w-[100%] h-11 bg-white border-primary border hover:bg-primary text-primary hover:text-white" label={"Add Review"} />}

                            {otherReview != null && <div style={{ boxShadow: "6px 6px 8px 6px rgba(0,0,0,0.02)" }} className="bg-white border p-4 items-start mb-8 rounded-primary flex flex-col w-[100%] border-card-border">
                                <div className="flex flex-row mb-1 gap-3">
                                    <img className="h-[50px] aspect-square rounded-[50%] object-cover" src={`${domain}/static/${otherProfile.profileImage}`} alt="profile" />
                                    <div className="flex flex-col flex-grow">
                                        <h1 className="text-black font-semibold text-md line-clamp-2">{`${otherProfile.firstName} ${otherProfile.lastName}`}</h1>
                                        <div className="flex gap-1">
                                            {Array.from({ length: 5 }).map((_, index) => (
                                                <img key={index} className={`h-4 w-4 aspect-square object-contain ${index + 1 > otherReview.reviews ? "filter-grey" : ""}`}
                                                    src={Star}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <p className="text-sm font-medium">{otherReview.review}</p>
                                <div className="flex flex-row flex-wrap gap-2 mt-4">
                                    {otherReview.images.map((image, index) =>
                                        <img key={index} className="h-[50px] w-[50px] rounded-[5px] object-cover" src={`${domain}/static/${image}`} alt={`pic-${index}`} />
                                    )}
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>;
};

export default CompletedGigScreen;