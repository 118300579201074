import React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from "react-router-dom";

import Button from './buttton';
import ProfileAvatar from './profileavatar';
import Logo from '../../assets/icons/homescreen/logo.svg';
import { domain } from '../../axios/adapter';
import LoginDialogBody from '../../dialogs/alertdialog/alertdialog_login';
import * as AlertDialog from "../../redux/slices/alertdialog";

export default function Header() {
    const [isOpen, setIsOpen] = useState(false);
    const dispatcher = useDispatch();
    const location = useLocation();
    const userSlice = useSelector((state) => state.userSlice);

    const subscription = userSlice.profileCompletion?.subscription;

    const toggleNavbar = () => {
        setIsOpen(!isOpen);
    };

    const onLogin = () => {
        dispatcher(AlertDialog.show({ child: <LoginDialogBody /> }));
    };

    // const scrollToSection = (id) => {
    //     document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
    // };


    return (
        <>
            <header>
                <nav className="bg-white shadow-[0_4px_24px_0px_rgba(0,0,0,0.08)]">
                    <div className="mx-auto max-w-7xl py-3 px-3">
                        <div className="relative flex h-16 items-center justify-between">
                            <div className="flex items-center lg:hidden">
                                {/* <!-- Mobile menu button--> */}
                                <button type="button" className="relative inline-flex items-center justify-center rounded-md p-2 text-black-400" aria-controls="mobile-menu" aria-expanded="false" onClick={toggleNavbar}>
                                    <span className="absolute -inset-0.5"></span>
                                    <span className="sr-only">Open main menu</span>
                                    <svg className="block h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                                    </svg>
                                    <svg className="hidden h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </button>
                            </div>
                            <div className="flex flex-1 items-center lg:justify-around">
                                <div className="flex flex-shrink-0 items-center logo-homescreen">
                                    <Link to={"/"}>
                                        <img loading='lazy' className="h-[54px] w-auto" src={Logo} alt="OnceUSA Logo" />
                                    </Link>
                                </div>
                                <div className="hidden lg:ml-6 lg:flex items-center">
                                    <div className="flex space-x-4 menu-wrapper">
                                        <Link to={'/'} className={`flex items-center justify-start lg:justify-center hover:text-lightGray text-darkGray uppercase rounded-md px-2 py-2 text-sm font-medium ${location.pathname == '/' && "active font-semibold"}`}>Home</Link>
                                        <Link to="/#categories" className="flex items-center justify-start lg:justify-center hover:text-lightGray text-darkGray uppercase rounded-md px-2 py-2 text-sm font-medium cursor-pointer">
                                            Categories
                                        </Link>
                                        <Link to="/#reviews" className="flex items-center justify-start lg:justify-center hover:text-lightGray text-darkGray uppercase rounded-md px-2 py-2 text-sm font-medium">Reviews</Link>
                                        <Link to='/#pricing' className="flex items-center justify-start lg:justify-center hover:text-lightGray text-darkGray uppercase rounded-md px-2 py-2 text-sm font-medium">Pricing</Link>
                                        <Link to="/#features" className="flex items-center justify-start lg:justify-center hover:text-lightGray text-darkGray uppercase rounded-md px-2 py-2 text-sm font-medium">features</Link>
                                        <Link to="/#faq" className="flex items-center justify-start lg:justify-center hover:text-lightGray text-darkGray uppercase rounded-md px-2 py-2 text-sm font-medium">Faq</Link>
                                        <Link to='/contact-us' className="flex items-center justify-start lg:justify-center hover:text-lightGray text-darkGray uppercase rounded-md px-2 py-2 text-sm font-medium">Contact</Link>
                                    </div>
                                </div>
                            </div>
                            {
                                (userSlice.user.uuid != -1 && userSlice.user.uuid.length != 0 && userSlice.profile.uuid != -1) ?
                                    <Link to="/SubscriptionInfo" state={{
                                        label: "My Subcription",
                                        subscription: subscription,
                                    }} className='flex flex-row gap-2 h-10 items-center ml-3'>
                                        {(subscription?.id ?? -1) != -1 && <div className='border-primary gap-2 px-2 cursor-pointer bg-primary-back flex flex-row justify-between items-center rounded-[10px] border h-[100%]'>
                                            <div className='w-[30px]'>
                                                <img src={`${domain}/static/${subscription.icon}`} height="100%" width="100%" alt='subscription' />
                                            </div>
                                            <h1>{window.innerWidth < 640 ? subscription.name : `${subscription.name} Plan`}</h1>
                                        </div>}
                                        <ProfileAvatar width='w-10' isItMe={true} photo={userSlice.profile.profileImage} />
                                    </Link>
                                    : <div className="inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                                        <Button child="Get started" extraCss="min-w-[160px] h-[46px] uppercase" onClick={() => onLogin()} />
                                    </div>
                            }
                        </div>
                    </div>

                    {/* <!-- Mobile menu, show/hide based on menu state. --> */}
                    <div className={`lg:hidden ${isOpen ? 'block' : 'hidden'}`} id="mobile-menu">
                        <div className="space-y-1 px-2 pb-3 pt-2">
                            <Link to={'/'} className={`flex items-center justify-start lg:justify-center hover:text-lightGray text-darkGray uppercase rounded-md px-2 py-2 text-sm font-medium ${location.pathname == '/' && "active font-semibold"}`}>Home</Link>
                            {/* <Link to={'/dashboard'} className={`flex items-center justify-start lg:justify-center hover:text-lightGray text-darkGray uppercase rounded-md px-2 py-2 text-sm font-medium ${location.pathname == '/dashboard' && "active font-semibold"}`}>Dashboard</Link> */}
                            <Link to='/#categories' className="flex items-center justify-start lg:justify-center hover:text-lightGray text-darkGray uppercase rounded-md px-2 py-2 text-sm font-medium">Categories</Link>
                            <Link to='/#reviews' className="flex items-center justify-start lg:justify-center hover:text-lightGray text-darkGray uppercase rounded-md px-2 py-2 text-sm font-medium">Reviews</Link>
                            <Link to='/#pricing' className="flex items-center justify-start lg:justify-center hover:text-lightGray text-darkGray uppercase rounded-md px-2 py-2 text-sm font-medium">Pricing</Link>
                            <Link to='/#features' className="flex items-center justify-start lg:justify-center hover:text-lightGray text-darkGray uppercase rounded-md px-2 py-2 text-sm font-medium">features</Link>
                            <Link to='/#faq' className="flex items-center justify-start lg:justify-center hover:text-lightGray text-darkGray uppercase rounded-md px-2 py-2 text-sm font-medium">Faq</Link>
                            <Link to='/contact-us' className="flex items-center justify-start lg:justify-center hover:text-lightGray text-darkGray uppercase rounded-md px-2 py-2 text-sm font-medium">Contact</Link>
                        </div>
                    </div>
                </nav>
            </header >
        </>
    );
}