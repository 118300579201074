import dateFormat from "dateformat";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { ReactComponent as Checkmark } from "../../../assets/icons/chatrooms/checkmark.svg";
import { ReactComponent as DoubleCheckmark } from "../../../assets/icons/chatrooms/doublecheckmark.svg";
import { domain } from "../../../axios/adapter";
import fetch from "../../../axios/manager";
import * as Loader from "../../../dialogs/loader/loaderop";
import showToast from "../../../dialogs/loader/toast";
import ImageVideoCarousel from "../../../global/components/image-video-carousel";
import ProfileAvatar from "../../../global/components/profileavatar";
import * as Alertdialog from "../../../redux/slices/alertdialog";
import { updateChat } from "../../../redux/slices/chatrooms";
import { addItem } from "../../../redux/slices/joblistings";
import OutlinedButton from "../../homepage/components/outlinedbutton";

const Chatbubble = ({ myProfile, otherProfile, chat, job, service, chatroomUUID, channelUUID, otherProfileType }) => {
    const isEstimate = chat.chatType === 'estimate';
    const accepted = chat.estimate?.estimate?.acceptedStatus;
    const userSlice = useSelector((state) => state.userSlice);

    const profile = userSlice.profile;

    const navigate = useNavigate();
    const dispatcher = useDispatch();

    const sendback = isEstimate ? "bg-[#F9F9F9]" : "bg-[#E4F1FF]";
    const sendborder = isEstimate ? "border-[#DDDDDD]" : "border-[#89C2FF]";
    const back = isEstimate ? "bg-[#F9F9F9]" : "bg-[#FFFFFF]";
    const border = isEstimate ? "border-[#DDDDDD]" : "border-[#D3D3D3]";
    const isSentFromMe = myProfile.id === chat.sentFrom;

    const sentFrom = isSentFromMe ? myProfile : otherProfile;

    const getPrice = () => {
        if (chat.estimate.price !== undefined) {
            return chat.estimate.price;
        }

        let price = 0;
        for (let i = 0; i < chat.estimate.milestones.length; i++) {
            const milestone = chat.estimate.milestones[i];
            price += milestone.price;
        }

        return price;
    };

    const onViewEstimateClicked = () => {
        navigate("/showEstimateScreen", {
            state: {
                chat: chat,
                chatroomUUID: chatroomUUID,
                channelUUID: channelUUID,
                job: job,
                service: service,
                otherProfile: otherProfile,
            }
        });
    };

    const updateEstimate = async (status) => {
        Loader.show();

        const [, error] = await fetch({
            route: `chats/auth/estimates/${chat.estimate.estimate.uuid}`,
            requestType: "put",
            body: {
                otherProfileUUID: otherProfile.uuid,
                acceptedStatus: status,
                chatroomUUID: chatroomUUID,
                channelUUID: channelUUID,
                chatUUID: chat.uuid,
            }
        });

        if (error != null) {
            return onError(error);
        }

        dispatcher(updateChat({
            chatroomUUID: chatroomUUID,
            channelUUID: channelUUID,
            chat: {
                ...chat,
                estimate: {
                    ...chat.estimate,
                    estimate: {
                        ...chat.estimate.estimate,
                        acceptedStatus: status,
                    }
                }
            },
        }));

        if (status != 1) {
            return Loader.hide();
        }

        const [completedGig, gigError] = await fetch({
            route: "completedGigs/auth/",
            requestType: "post",
            body: {
                clientProfileUUID: profile.uuid,
                providerProfileUUID: otherProfile.uuid,
                estimateUUID: chat.estimate.estimate.uuid,
                jobUUID: job?.uuid,
                serviceUUID: service?.uuid,
            }
        });

        if (gigError != null) {
            return onError(error);
        }

        Loader.hide();

        dispatcher(addItem({ item: completedGig.res, type: "progress" }));
    };

    const onError = (error) => {
        showToast(error);
        return Loader.hide();
    };

    function onImageClick(image) {
        dispatcher(Alertdialog.show({
            child: <ImageVideoCarousel items={
                [<img key={1} className="cursor-pointer w-full h-[50rem] object-contain" src={`${domain}/static/${image}`} alt="chat" />]
            } />,
            className: "bg-transparent !shadow-none",
        }));
    }

    return <div className={`w-[100%] flex flex-row ${isSentFromMe ? 'justify-end' : 'justify-start'}`}>
        <div className={`flex ${isSentFromMe ? 'flex-row' : 'flex-row-reverse'} gap-2 max-w-[70%] ${isEstimate ? 'w-[400px]' : ''}`}>
            <div className={`flex flex-col gap-1 ${isSentFromMe ? 'items-end' : 'items-start'} ${isEstimate ? 'w-[100%]' : ''}`}>
                <div className={`rounded-primary ${isSentFromMe ? `${sendback} ${sendborder} ${isEstimate ? 'w-[100%]' : ''}` : `${back} ${border}`} border ${chat.chat != null ? 'px-3 py-2' : 'p-2'}`}>
                    {chat.chat != null && chat.chat}
                    {chat.image != null && <img src={`${domain}/static/${chat.image}`} onClick={() => onImageClick(chat.image)} className="object-cover cursor-pointer h-[250px] rounded-primary" alt="chat" />}
                    {isEstimate &&
                        <div className="flex flex-col gap-1 w-[100%] px-2 py-1 min-w-[300px]">
                            <div className="flex grow w-[100%] flex-row justify-between">
                                <h4 className="font-medium">{job?.title ?? service?.title}</h4>
                                <h4 className="text-black font-semibold">${getPrice()}</h4>
                            </div>
                            <div className="w-[100%] h-[5px] bg-darkGray bg-opacity-5 mt-1 mb-2" />

                            <p className="text-darkGray text-sm font-normal">{job?.fullDescription ?? service?.fullDescription}</p>

                            <div className="flex flex-row gap-2 justify-stretch h-10 mt-3">
                                <OutlinedButton onClicked={() => onViewEstimateClicked()} extracss="w-[100%] border-primary bg-white hover:bg-primary hover:text-white text-primary transition-all ease-in-out duration-200" label={"View"} />
                                {(userSlice.user.userType != "provider" || (accepted ?? -1) != -1) && <OutlinedButton onClicked={() => {
                                    if ((accepted ?? -1) == -1) {
                                        updateEstimate(1);
                                    }
                                }}
                                    extracss={`w-[100%] ${accepted == -1 ? 'primary-gradient' : accepted == 1 ? 'bg-primary' : 'bg-red'} text-white border-none`}
                                    label={accepted == -1 ? 'Accept' : accepted == 1 ? 'Accepted' : 'Rejected'} />}
                            </div>
                        </div>
                    }
                </div>
                <div className="flex flex-row items-center gap-1">
                    <h1 className="text-xs font-normal text-lightGray text-ellipsis">{dateFormat(chat.createdAt, "h:mm tt")}</h1>
                    {isSentFromMe && (
                        <div className="mr-[2px]">
                            {chat.readStatus == 0 ? <Checkmark className="w-[12px] h-[100%] object-cover" style={{ filter: "invert(40%) sepia(90%) saturate(0%) hue-rotate(135deg) brightness(92%) contrast(69%)" }} />
                                : chat.readStatus == 1 ? <Checkmark className="w-[12px] h-[100%] object-cover filter-grey" />
                                    : <DoubleCheckmark className="w-[17px] mb-[2px] h-[100%] object-cover" />}
                        </div>
                    )}
                </div>
            </div>
            <ProfileAvatar width='w-[25px]' checkmark={false} profile={sentFrom} type={otherProfileType} isItMe={isSentFromMe} photo={sentFrom.profileImage} />
        </div>
    </div>;
};

export default Chatbubble;