import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

export default function ImageVideoCarousel({ items }) {
    return <div className="relative">
        <Swiper
            modules={[]}
            className="w-[50vw]"
            spaceBetween={50}
            slidesPerView={1}>
            {items.map((item, index) => <SwiperSlide key={index}>{item}</SwiperSlide>)}
        </Swiper>
    </div>;
}