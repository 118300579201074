import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { ReactComponent as SearchIcon } from "../../../assets/icons/chatrooms/search.svg";
import fetch from "../../../axios/manager";
import AlertDialog from "../../../dialogs/alertdialog/alertdialog";
import * as Loader from "../../../dialogs/loader/loaderop";
import showToast from "../../../dialogs/loader/toast";
import { PaginatedScrollViewChat } from "../../../global/components/paginatedscrollview";
import ProfileAvatar from "../../../global/components/profileavatar";
import { setChatroom } from "../../../redux/slices/chatrooms";
import * as NotificationRedux from "../../../redux/slices/notifications";
import { setProgress } from "../../../redux/slices/toploadingbar";

const ChatDashboard = () => {
    const dispatcher = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const userSlice = useSelector((state) => state.userSlice);
    const chatrooms = useSelector((state) => Object.values(state.chatroomsSlice));
    const notificationsSlice = useSelector(state => state.notificationsSlice);

    const [chatroomUUID, setChatroomUUID] = useState(location.state?.chatroomUUID);
    const [channelUUID, setChannelUUID] = useState(location.state?.channelUUID);

    const [generalNotificationCount, workNotificationCount] = getTabNotifications();

    function getTabNotifications() {
        let generalCount = 0;
        let workCount = 0;

        for (let i = 0; i < chatrooms.length; i++) {
            const chatroom = chatrooms[i];

            Object.values(chatroom.channels).map(channel => {
                const channelUUID = channel.uuid;
                const chatroomUUID = chatroom.uuid;

                const count = notificationsSlice.notificationData[`${chatroomUUID}~${channelUUID}`] ?? 0;

                if (channelUUID == "-1") {
                    if (count > 0) {
                        generalCount++;
                    }
                }
                else {
                    if (count > 0) {
                        workCount++;
                    }
                }
            });
        }

        return [generalCount, workCount];
    }

    const fetchChatrooms = async (offset, limit) => {
        if (offset == 0 && chatrooms.length != 0) {
            return chatrooms;
        }

        dispatcher(setProgress(50));
        Loader.show();

        const [result, error] = await fetch({
            route: "chats/auth/chatrooms/list/all",
            requestType: "get",
            cacheKey: `chatrooms-${offset}`,
            params: {
                "limit": limit,
                "offset": offset,
                "sort": "DESC",
            },
            body: {
                chatroomUUID: chatroomUUID,
            }
        });

        if (error != null) {
            onError(error);
            return [];
        }

        const chatroomsData = result.res;

        for (let i = 0; i < chatroomsData.length; i++) {
            const channels = {};

            chatroomsData[i].channels.map((item) => {
                channels[item.uuid] = item;

                dispatcher(NotificationRedux.setNotifications({ chatroomUUID: chatroomsData[i].uuid, channelUUID: item.uuid, count: item.notificationCount ?? 0 }));

                return item;
            });
            chatroomsData[i].channels = channels;
            dispatcher(setChatroom({ item: chatroomsData[i] }));
        }

        dispatcher(setProgress(100));
        Loader.hide();

        if (chatroomUUID != null && channelUUID != null) {
            navigate(`/chatRooms/${chatroomUUID}/channel/${channelUUID}`);

            setChatroomUUID(null);
            setChannelUUID(null);
        }

        return chatroomsData;
    };

    function onError(error) {
        showToast(error);
        dispatcher(setProgress(100));
        return Loader.hide();
    }

    return <main>
        <AlertDialog />
        <div className="flex flex-row w-[100%] h-[100vh]">
            <div className="w-[450px] h-[100%] flex flex-col items-stretch">
                <div style={{ backgroundColor: '#F1F1F1', borderRight: '1px solid #D2D2D2' }} className="h-[70px] shrink-0 flex flex-row items-center justify-start px-5">
                    <ProfileAvatar width='w-10' isItMe={true} photo={userSlice.profile.profileImage} />
                </div>
                <div style={{ backgroundColor: "#F9F9F9", borderRight: '1px solid #E8E8E8' }} className="h-[70px] p-3 shrink-0">
                    <div id="searchBar" className="flex flex-row items-center gap-2 h-[100%] overflow-hidden px-3">
                        <SearchIcon />
                        <input type="text" placeholder="Search" className="w-[100%] h-[100%] outline-none text-black text-md pb-[2px] font-medium border-none bg-[#F0F0F0]" />
                    </div>
                </div>

                <div style={{ boxShadow: "14px 0px 24px 0px rgba(0,0,0,0.05)" }} className="flex flex-col h-[100%] overflow-y-auto grow">
                    <PaginatedScrollViewChat notifications={[generalNotificationCount, workNotificationCount]} onNotFound="Empty room. No chats here." limit={10} pageRequest={fetchChatrooms} />
                </div>
            </div>
            <div className="flex grow bg-[#F0F0F0]" >
                <Outlet />
            </div>
        </div>
    </main>;
};

export default ChatDashboard;