import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    visible: false,
    className: "",
    child: null,
};

const AlertDialogSlice = createSlice({
    name: 'alertdialog',
    initialState,
    reducers: {
        show: (state, action) => {
            state.child = action.payload.child;
            state.className = action.payload.className;
            state.visible = true;
        },
        hide: (state) => {
            state.child = null;
            state.className = null;
            state.visible = false;
        },
    }
});

export const { show, hide } = AlertDialogSlice.actions;
export default AlertDialogSlice.reducer;