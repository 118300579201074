import React from "react";

import { ReactComponent as Upload } from "../../../assets/icons/dashboard/upload.svg";

const UploadContainer = ({ extraCss, onClick, error }) => {
    const border = error ? "border border-red" : "border-textfield-stroke border-dashed";

    return <div style={{ backgroundColor: "#F0F0F0" }} onClick={onClick} className={`${extraCss} ${border} transition-all duration-200 ease-in-out cursor-pointer hover:border-solid hover:border-primary border-2 rounded-primary flex flex-col items-center justify-center`}>
        <Upload className="h-[20%] w-[20%]" height="100%" width="100%" />
        <h1 className="text-lg text-darkGray font-medium">Upload</h1>
    </div>;
};

export default UploadContainer;