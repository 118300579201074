import { useState, React } from "react";

const TabBar = ({ items, extraCss, onChange, width, outerRounded, innerRounded, notifications = [0, 0] }) => {
    const [current, setCurrent] = useState(0);

    return <div className={`relative flex flex-row bg-card-border ${outerRounded} items-center ${extraCss} cursor-pointer px-1 overflow-hidden py-1`}>
        <div className="w-[100%] h-[100%] flex items-start z-[1]">
            <div style={{ transform: `translateX(${current * 100}%)` }} className={`bg-primary ${innerRounded} transition-all duration-300 h-[100%] ${width}`} />
        </div>

        <div className="flex flex-row absolute w-[100%] h-full overflow-visible">
            {items.map((item, index) => {
                return (
                    <div key={item} onClick={() => {
                        setCurrent(index);
                        onChange(index);
                    }} style={{ color: (current === index) ? "#fff" : "#333333" }} className="transition-all relative duration-300 ease-in-out flex-1 h-[100%] flex tet-2xl justify-center items-center z-[1] font-semibold">
                        <div>{item}</div>

                        {((notifications[index] ?? 0) != 0) && <div className="h-4 w-4 aspect-square rounded-[50%] bg-red grid place-content-center absolute top-1 right-1">
                            <div className="text-xs text-white">{notifications[index]}</div>
                        </div>}
                    </div>
                );
            })}
        </div>
    </div>;
};


export default TabBar;