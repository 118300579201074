import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { ReactComponent as EditIcon } from "../../../assets/icons/dashboard/edit-icon.svg";
import HalfStar from "../../../assets/icons/homescreen/half-star.svg";
import StarFilled from "../../../assets/icons/homescreen/star-fill.svg";
import { domain } from "../../../axios/adapter";
import fetch from "../../../axios/manager";
import * as Loader from "../../../dialogs/loader/loaderop";
import showToast from "../../../dialogs/loader/toast";
import Button from "../../../global/components/buttton";
import ImageVideoCarousel from "../../../global/components/image-video-carousel";
import VideoContainer from "../../../global/components/videocontainer";
import * as Alertdialog from "../../../redux/slices/alertdialog";
import { updateChatroom } from "../../../redux/slices/chatrooms";
import { setProgress } from "../../../redux/slices/toploadingbar";

function ServiceInfo() {
    const location = useLocation();
    const dispatcher = useDispatch();
    const navigate = useNavigate();

    const serviceSlice = useSelector((state) => state.jobListingSlice.services);

    const isItMe = location.state.isItMe;
    const service = serviceSlice[location.state.service.uuid] ?? location.state.service;
    const [reviews, setReviews] = useState(null);

    const [chatroomExists, setChatroomExists] = useState(false);

    useEffect(() => {
        fetchReviews();

        if (!isItMe) {
            checkChatroomExists();
        }
    }, []);

    async function checkChatroomExists() {
        Loader.show();

        const [chatroomData, error] = await fetch({
            route: `chats/auth/services/${service.uuid}`,
            requestType: "get",
            cacheKey: `chatroomExists${service.uuid}`,
        });

        if (error != null) {
            console.log(error);
            return Loader.hide();
        }

        Loader.hide();

        if (chatroomData != null) {
            setChatroomExists(true);
        }
    }

    const fetchReviews = async () => {
        dispatcher(setProgress(50));
        Loader.show();

        const [result, error] = await fetch({
            route: `/reviews/auth/service/${service.uuid}`,
            requestType: "get",
            params: {
                "limit": 2,
            },
        });

        if (error != null) {
            onError(error);
            return;
        }

        const reviews = result.res;

        dispatcher(setProgress(100));
        Loader.hide();

        if (reviews.length != 0) {
            setReviews(reviews);
        }
    };

    async function hire() {
        Loader.show();
        dispatcher(setProgress(50));

        const [chatroomData, chatroomError] = await fetch({
            route: `chats/auth/chatrooms/${service.profile.uuid}`,
            requestType: "post",
        });

        if (chatroomError != null) {
            return onError(chatroomError);
        }

        const chatroom = chatroomData.res;

        dispatcher(setProgress(75));

        const [channelData, channelError] = await fetch({
            route: "chats/auth/channels",
            requestType: "post",
            body: {
                name: service.title,
                chatroomUUID: chatroom.uuid,
                serviceUUID: service.uuid,
            }
        });

        dispatcher(setProgress(100));
        Loader.hide();

        if (channelError != null) {
            return onError(channelError);
        }

        const channel = channelData.res[0];

        chatroom.channels = {
            ...chatroom.channels,
            ["-1"]: { id: "-1", uuid: "-1" },
            [channel.uuid]: channel,
        };

        chatroom.otherProfile = service.profile;

        dispatcher(updateChatroom({ item: chatroom }));

        navigate(`/chatRooms/${chatroom.uuid}/channel/${channel.uuid}`, {
            state: {
                chatroomUUID: chatroom.uuid,
                service: service,
                channel: channel,
            }
        });
    }

    function onError(error) {
        showToast(error);
        dispatcher(setProgress(100));
        return Loader.hide();
    }

    function onImageClick(images) {
        dispatcher(Alertdialog.show({
            child: <ImageVideoCarousel items={[...images.map((image) =>
                <img key={image} className="cursor-pointer w-full h-[50rem] object-contain" src={`${domain}/static/${image}`} alt="job" />
            )]} />,
            className: "bg-transparent !shadow-none",
        }));

    }

    return <main>
        <section>
            <div className="flex flex-col w-[100%] mb-10">
                <div className="mx-10">
                    <div className="mt-6 w-[100%] xl:px-32 lg:px-20 flex flex-col">
                        <div className="flex flex-row justify-between items-center w-[100%]">
                            <h1 className="text-black font-semibold mb-10 text-3xl mt-12">Service Details</h1>
                            {isItMe && <Link to="/EditService" state={{ service: service }}>
                                <button style={{ backgroundColor: "#F2F2F2", border: "1px solid #DDDDDD" }} className="filter-icon rounded-primary h-11 w-[120px] justify-center transition-all duration-200 ease-in-out flex flex-row items-center gap-2">
                                    <EditIcon />
                                    <h1 className="flex flex-row gap-2 text-darkGray font-semibold text-md capitalize">edit</h1>
                                </button>
                            </Link>}
                        </div>
                        <div className="w-[100%] h-[1px] bg-darkGray bg-opacity-20" />

                        <div className="flex flex-row justify-start items-start my-5 gap-4">
                            <img className="rounded-primary w-[100px] h-[100px] object-cover" src={`${domain}/static/${service.images[0]}`} alt="job" />
                            <div className="flex flex-col gap-2">
                                <h1 className="text-black font-semibold text-xl">{service.title}</h1>
                                <div style={{ boxShadow: "6px 6px 8px 6px rgba(0,0,0,0.03)" }}
                                    className="flex py-2 px-3 flex-row bg-white border items-center border-[#E8E8E8] rounded-primary gap-3 w-max">
                                    <div className="flex flex-row justify-start items-center flex-nowrap">
                                        {Array.from({ length: 5 }).map((_, index) => {
                                            return (((index + 1) - service.reviews) < 1 &&
                                                ((index + 1) - service.reviews) > 0 ?
                                                <img src={HalfStar} className="h-4 w-4" height="100%" width="100%" key={`highlighted-${index}`} /> :
                                                <img src={StarFilled} className="h-4 w-4" height="100%" style={(index + 1) <= service.reviews ? {} : { filter: "invert(76%) sepia(70%) saturate(17%) hue-rotate(358deg) brightness(184%) contrast(84%)" }}
                                                    key={`star-${index}`} />);
                                        })}
                                    </div>
                                    <h1 className="text-Gray46 font-medium text-sm">{service.reviewsCount} Reviews</h1>
                                </div>
                            </div>
                        </div>
                        <p className="text-Gray46 font-medium text-md w-[80%]">{service.fullDescription}</p>

                        <div className="2xl:w-[60%] w-[65%] max-md:w-[75%] gap-4 flex flex-row flex-wrap justify-start py-8">
                            {service.videos.map((video) => <VideoContainer className="w-[130px] h-[130px]" key={video} url={video} />)}
                            {service.images.map((image) => <img key={image} onClick={() => onImageClick(service.images)} className="cursor-pointer rounded-primary w-[100px] h-[100px] object-cover" src={`${domain}/static/${image}`} alt="job" />)}
                        </div>

                        {isItMe == false && <Button child={chatroomExists ? "Go To Chatroom" : "Hire For Work"} extraCss={'h-11 w-[500px]'} onClick={hire} />}

                        {reviews != null && <h1 className="text-black font-semibold text-xl my-5 mb-2">{isItMe ? "My Reviews" : "Reviews"}</h1>}

                        {reviews != null && <div className="flex flex-col w-[500px]">
                            {reviews.map(item => <div key={item.uuid} style={{ boxShadow: "6px 6px 8px 6px rgba(0,0,0,0.02)" }} className="bg-white border p-4 items-start mb-8 rounded-primary flex flex-col w-[100%] border-card-border">
                                <Link to="/ClientPublicProfile" state={{ profile: item.profile }} className="flex flex-row mb-1 gap-3 cursor-pointer">
                                    <img className="h-[50px] aspect-square rounded-[50%] object-cover" src={`${domain}/static/${item.profile.profileImage}`} alt="profile" />
                                    <div className="flex flex-col flex-grow">
                                        <h1 className="text-black font-semibold text-md line-clamp-2">{`${item.profile.firstName} ${item.profile.lastName}`}</h1>
                                        <div className="flex gap-1">
                                            {Array.from({ length: 5 }).map((_, index) => (
                                                <img key={index} className={`h-4 w-4 aspect-square object-contain ${index + 1 > item.reviews ? "filter-grey" : ""}`}
                                                    src={StarFilled}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                </Link>
                                <p className="text-sm font-medium">{item.review}</p>
                                <div className="flex flex-row flex-wrap gap-2 mt-4">
                                    {item.images.map((image, index) =>
                                        <img key={index} className="h-[50px] w-[50px] rounded-[5px] object-cover" src={`${domain}/static/${image}`} alt={`pic-${index}`} />
                                    )}
                                </div>
                            </div>)}
                        </div>}
                    </div>
                </div>
            </div>
        </section>
    </main>;
}

export default ServiceInfo;