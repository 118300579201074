import dateFormat from "dateformat";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { ReactComponent as Calender } from "../../../assets/icons/dashboard/calender.svg";
import { ReactComponent as Location } from "../../../assets/icons/dashboard/location.svg";
import Star from "../../../assets/icons/homescreen/star-fill.svg";
import { domain } from "../../../axios/adapter";
import fetch from "../../../axios/manager";
import * as Loader from "../../../dialogs/loader/loaderop";
import showToast from "../../../dialogs/loader/toast";
import ImageVideoCarousel from "../../../global/components/image-video-carousel";
import VideoContainer from "../../../global/components/videocontainer";
import generateId from "../../../global/methods/generateid";
import { toFormData } from "../../../global/methods/toformdata";
import * as Alertdialog from "../../../redux/slices/alertdialog";
import { updateChat } from "../../../redux/slices/chatrooms";
import { addItem, updateItem } from "../../../redux/slices/joblistings";
import { setProgress } from "../../../redux/slices/toploadingbar";
import OutlinedButton from "../../homepage/components/outlinedbutton";
import AddReviewDialog from "../../jobs/screens/addreview";
import EstimateContainer from "../components/estimate_container";

const ShowEstimateScreen = () => {
    const location = useLocation();
    const dispatcher = useDispatch();
    const userSlice = useSelector((state) => state.userSlice);

    const { chat, chatroomUUID, channelUUID, otherProfile, job, service } = location.state;

    const [mchat, setChat] = useState({ ...chat });

    const item = job ?? service;
    const user = userSlice.user;
    const profile = userSlice.profile;

    const [gig, setGig] = useState(null);

    const accepted = mchat.estimate.estimate.acceptedStatus;

    const myReview = user.userType == "provider" ? gig?.providerReview : gig?.clientReview;
    const otherReview = user.userType == "provider" ? gig?.clientReview : gig?.providerReview;

    useEffect(() => {
        fetchCompletedGig();
    }, []);

    async function fetchCompletedGig() {
        Loader.show();

        const [completedGigData, completedGigError] = await fetch({
            route: `completedGigs/auth/estimate/${mchat.estimate.estimate.uuid}`,
            requestType: "get",
        });

        if (completedGigError != null) {
            console.log(completedGigError);

            return Loader.hide();
        }

        const myType = user.userType;

        const gigObj = {
            ...completedGigData.res,
            [myType == "provider" ? "providerProfile" : "clientProfile"]: profile,
        };

        Loader.hide();

        setGig({ ...gigObj });

        dispatcher(addItem({ type: "completed", item: gigObj }));
    }

    const onAccepted = () => {
        updateEstimate(1);
    };

    const onRejected = () => {
        updateEstimate(0);
    };

    const updateEstimate = async (status) => {
        Loader.show();
        dispatcher(setProgress(50));
        const [, error] = await fetch({
            route: `chats/auth/estimates/${mchat.estimate.estimate.uuid}`,
            requestType: "put",
            body: {
                otherProfileUUID: otherProfile.uuid,
                acceptedStatus: status,
                chatroomUUID: chatroomUUID,
                channelUUID: channelUUID,
                chatUUID: mchat.uuid,
            }
        });

        if (error != null) {
            return onError(error);
        }

        dispatcher(updateChat({
            chatroomUUID: chatroomUUID,
            channelUUID: channelUUID,
            chat: {
                ...mchat,
                estimate: {
                    ...mchat.estimate,
                    estimate: {
                        ...mchat.estimate.estimate,
                        acceptedStatus: status,
                    }
                }
            },
        }));


        if (status != 1) {
            dispatcher(setProgress(100));
            return Loader.hide();
        }

        dispatcher(setProgress(75));

        const [completedGig, gigError] = await fetch({
            route: "completedGigs/auth/",
            requestType: "post",
            body: {
                clientProfileUUID: profile.uuid,
                providerProfileUUID: otherProfile.uuid,
                estimateUUID: mchat.estimate.estimate.uuid,
                jobUUID: job?.uuid,
                serviceUUID: service?.uuid,
            }
        });

        if (gigError != null) {
            return onError(error);
        }

        setProgress(100);
        Loader.hide();

        dispatcher(addItem({ item: completedGig.res, type: "progress" }));
    };

    function onMarkAsPaidClicked() {
        const milestones = mchat.estimate.milestones;

        if (milestones.length == 1) {
            return createTransaction();
        }

        for (let i = 0; i < milestones.length; i++) {
            if (milestones[i].acceptedStatus == -1) {
                return updateMilestoneStatus(i);
            }
        }

        createTransaction();
    }

    async function createTransaction() {
        Loader.show();
        dispatcher(setProgress(50));

        const [transactionData, transactionError] = await fetch({
            route: "payments/auth/transactions",
            requestType: "post",
            body: {
                transactionId: generateId(10),
                amount: mchat.estimate.estimate.price,
                paidToUUID: profile.uuid,
            }
        });

        if (transactionError != null) {
            return onError(transactionError);
        }

        dispatcher(setProgress(75));

        const transactionObj = transactionData.res;

        const [, completedGigError] = await fetch({
            route: `completedGigs/auth/${gig.uuid}`,
            requestType: "put",
            body: {
                transactionUUID: transactionObj.uuid,
            }
        });

        if (completedGigError != null) {
            showToast(completedGigError);
            dispatcher(setProgress(100));
            return Loader.hide();
        }
        const gigObj = {
            ...gig,
            transaction: transactionObj,
        };

        setGig({ ...gigObj });

        dispatcher(updateItem({
            type: "completed",
            item: gigObj,
        }));

        dispatcher(setProgress(100));
        Loader.hide();
    }

    async function updateMilestoneStatus(i) {
        const milestone = mchat.estimate.milestones[i];

        Loader.show();
        const [, completedGigError] = await fetch({
            route: `completedGigs/auth/milestones/${milestone.uuid}`,
            requestType: "put",
        });

        if (completedGigError != null) {
            showToast(completedGigError);
            return Loader.hide();
        }

        Loader.hide();

        const chatObj = JSON.parse(JSON.stringify(mchat));

        chatObj.estimate.milestones[i].acceptedStatus = 1;

        dispatcher(updateChat({
            chatroomUUID: chatroomUUID,
            channelUUID: channelUUID,
            chat: chatObj,
        }));

        setChat({ ...chatObj });
    }

    const onError = (error) => {
        showToast(error);
        dispatcher(setProgress(100));
        return Loader.hide();
    };

    function getText() {
        const milestones = mchat.estimate.milestones;

        if (gig?.transaction == null) {
            if (milestones.length == 1) {
                return "Mark as Paid";
            }

            for (let i = 0; i < milestones.length; i++) {
                const milestone = milestones[i];

                if (milestone.acceptedStatus == -1) {
                    return `Mark ${milestone.name} as Paid`;
                }
            }

            return "Mark as Paid";
        }
    }

    function onAddReview() {
        dispatcher(Alertdialog.show({ child: <AddReviewDialog onSubmit={addReview} /> }));
    }

    async function addReview({ review, images, description }) {
        Loader.show();
        dispatcher(setProgress(50));

        const [reviewData, reviewError] = await fetch({
            route: "reviews/auth/",
            requestType: "post",
            body: toFormData({
                completedGigUUID: gig.uuid,
                givenStars: review,
                review: description,
                [gig.job == null ? "serviceUUID" : "jobUUID"]: gig.job?.uuid ?? gig.service?.uuid,
                images: images,
            }),
        });

        if (reviewError != null) {
            return onError(reviewError);
        }

        const reviewObj = reviewData.res;

        dispatcher(setProgress(75));

        const [, gigError] = await fetch({
            route: `completedGigs/auth/${gig.uuid}`,
            requestType: "put",
            body: {
                [user.userType == "provider" ? "providerReviewUUID" : "clientReviewUUID"]: reviewObj.uuid,
            }
        });

        if (gigError != null) {
            return onError(gigError);
        }

        const gigObj = {
            ...gig,
            [user.userType == "provider" ? "providerReview" : "clientReview"]: reviewObj,
        };

        setGig({ ...gigObj });

        dispatcher(updateItem({
            type: "completed",
            item: gigObj,
        }));

        dispatcher(setProgress(100));
        Loader.hide();
    }

    function onImageClick(images) {
        dispatcher(Alertdialog.show({
            child: <ImageVideoCarousel items={[...images.map((image) =>
                <img key={image} className="w-full h-[50rem] object-contain" src={`${domain}/static/${image}`} alt="job" />
            )]} />,
            className: "bg-transparent !shadow-none",
        }));
    }

    return <main>
        <section>
            <div className="flex flex-col w-[100%]">
                <div className="mx-10">
                    <div className="mt-6 w-[100%] xl:px-32 lg:px-20 flex flex-col">
                        <div className="flex flex-row justify-between items-center w-[100%]">
                            <h1 className="text-black font-normal mb-10 text-3xl mt-12">Estimate</h1>
                        </div>
                        <div className="w-[100%] h-[1px] bg-darkGray bg-opacity-20" />

                        <h1 className="text-black font-medium mb-5 text-3xl mt-12">{item.title}</h1>
                        <p className="text-darkGray opacity-50 w-[80%]">{item.fullDescription}</p>
                        <h1 className="text-darkGray font-semibold mt-5">Images:</h1>

                        <div className="2xl:w-[60%] w-[65%] max-md:w-[75%] gap-4 flex flex-row flex-wrap justify-start pt-3">
                            {item.videos.map((video) => <VideoContainer className="w-[130px] h-[130px]" key={video} url={video} />)}
                            {item.images.map((image) =>
                                <img key={image} onClick={() => onImageClick(item.images)} className="cursor-pointer rounded-primary w-[130px] h-[130px] object-cover" src={`${domain}/static/${image}`} alt="job" />
                            )}
                        </div>

                        <div className="w-[100%] h-[8px] bg-darkGray bg-opacity-5 my-5" />

                        <div className="px-4 py-4 items-start mb-10 rounded-primary flex flex-col w-[500px]">
                            <div className="flex flex-row items-start justify-between w-[100%] gap-4">
                                <img className="w-[18%] object-cover aspect-square rounded-[50%]" src={`${domain}/static/${otherProfile.profileImage}`} alt="profile" />
                                <div className="flex-grow flex flex-col gap-1 items-start justify-around">
                                    <h1 className="text-black font-semibold text-xl line-clamp-2">{`${otherProfile.firstName} ${otherProfile.lastName}`}</h1>
                                    <div className="flex flex-row w-[100%] justify-start items-start gap-2">
                                        <Location className="h-6 w-6 mt-[1px]" height="100%" width="100%" />
                                        <h1 className="text-lightGray text-sm mr-5 line-clamp-2 text-ellipsis">{`${otherProfile.location}, ${otherProfile.landmark}, ${otherProfile.state}`}</h1>
                                    </div>
                                    <div className="flex flex-row justify-start w-[100%] items-start gap-2">
                                        <Calender className="h-4 w-6 mt-[1px]" height="100%" width="100%" />
                                        <h1 className="text-lightGray text-sm mr-5 line-clamp-1">{dateFormat(item.createdAt, "d mmm, yyyy | h:mm tt")}</h1>
                                    </div>
                                </div>
                            </div>
                            <h1 className="text-black font-semibold text-md capitalize mt-2">Job Location:</h1>
                            <div className="flex flex-row w-[100%] justify-start items-center gap-2 mb-5">
                                <Location className="h-6 w-6 mt-[1px]" height="100%" width="100%" />
                                <h1 className="text-lightGray text-sm mr-5 line-clamp-2 text-ellipsis">{job != null ? `${job.address}, ${job.city}, ${job.state}` : `${otherProfile.location}, ${otherProfile.landmark}, ${otherProfile.state}`}</h1>
                            </div>

                            {<EstimateContainer item={mchat.estimate} paymentStatus={gig?.transaction == null ? "pending" : "paid"} />}
                            {(accepted ?? -1) == -1 && user.userType != "provider" && <div className="h-11 w-[100%] flex flex-row gap-4">
                                <OutlinedButton onClicked={() => onAccepted()} extracss="flex-grow primary-gradient border-none text-white rounded-primary" label="Accept" />
                                <OutlinedButton onClicked={() => onRejected()} extracss="flex-grow border-none bg-red text-white rounded-primary" label="Reject" />
                            </div>}
                            <div className="flex flex-col gap-2 w-full">
                                {(accepted == 1 && gig?.transaction == null) && <OutlinedButton onClicked={() => { }} extracss="bg-white h-11 border-primary text-primary text-md hover:text-white hover:border-white hover:bg-primary text-center" label="Accepted" />}
                                {(accepted == 0 && gig?.transaction == null) && <OutlinedButton onClicked={() => { }} extracss="bg-white h-11 border-red text-red text-md hover:text-white hover:border-white hover:bg-red text-center" label="Rejected" />}
                                {(gig != null && gig?.transaction == null && user.userType == "provider") && <OutlinedButton onClicked={onMarkAsPaidClicked} extracss="w-[100%] cursor-not-allowed border-none h-11 primary-gradient text-white rounded-primary" label={getText()} />}
                                {(gig?.transaction != null && myReview == null) && <OutlinedButton onClicked={onAddReview} extracss="bg-white h-11 border-primary text-primary text-md hover:text-white hover:border-white hover:bg-primary text-center" label="+ Add Review" />}
                            </div>
                            {otherReview != null && <div style={{ boxShadow: "6px 6px 8px 6px rgba(0,0,0,0.02)" }} className="bg-white border p-4 items-start mb-8 rounded-primary flex flex-col w-[100%] border-card-border">
                                <div className="flex flex-row mb-1 gap-3">
                                    <img className="h-[50px] aspect-square rounded-[50%] object-cover" src={`${domain}/static/${otherProfile.profileImage}`} alt="profile" />
                                    <div className="flex flex-col flex-grow">
                                        <h1 className="text-black font-semibold text-md line-clamp-2">{`${otherProfile.firstName} ${otherProfile.lastName}`}</h1>
                                        <div className="flex gap-1">
                                            {Array.from({ length: 5 }).map((_, index) => (
                                                <img key={index} className={`h-4 w-4 aspect-square object-contain ${index + 1 > otherReview.reviews ? "filter-grey" : ""}`}
                                                    src={Star}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <p className="text-sm font-medium">{otherReview.review}</p>
                                <div className="flex flex-row flex-wrap gap-2 mt-4">
                                    {otherReview.images.map((image, index) =>
                                        <img key={index} className="h-[50px] w-[50px] rounded-[5px] object-cover" src={`${domain}/static/${image}`} alt={`pic-${index}`} />
                                    )}
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main >;
};

export default ShowEstimateScreen;