import dateFormat from 'dateformat';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";


import { ReactComponent as Calender } from "../../../assets/icons/dashboard/calender.svg";
import { ReactComponent as EditIcon } from "../../../assets/icons/dashboard/edit-icon.svg";
import { ReactComponent as Location } from "../../../assets/icons/dashboard/location.svg";
import { domain } from "../../../axios/adapter";
import fetch from "../../../axios/manager";
import * as Loader from "../../../dialogs/loader/loaderop";
import showToast from '../../../dialogs/loader/toast';
import ImageVideoCarousel from '../../../global/components/image-video-carousel';
import VideoContainer from '../../../global/components/videocontainer';
import * as Alertdialog from "../../../redux/slices/alertdialog";
import { updateChatroom } from '../../../redux/slices/chatrooms';
import { deleteItem, updateItem } from "../../../redux/slices/joblistings";
import { setProgress } from '../../../redux/slices/toploadingbar';
import OutlinedButton from "../../homepage/components/outlinedbutton";

const JobInfo = () => {
    const location = useLocation();

    const joblistingSlice = useSelector((state) => state.jobListingSlice.mine);

    const job = joblistingSlice[location.state.job.uuid] ?? location.state.job;
    const label = location.state.label;
    const isItMe = location.state.isItMe;

    const [otherProfile, setOtherProfile] = useState();
    const [applicantsCount, setApplicantsCount] = useState(null);

    const [chatroomExists, setChatroomExists] = useState(false);

    useEffect(() => {
        if (!isItMe) {
            checkChatroomExists();
        }
    }, []);

    const dispatcher = useDispatch();
    const navigate = useNavigate();

    const onDelete = async () => {
        Loader.show();

        const [, error] = await fetch({
            route: `jobs/auth/${job.uuid}`,
            requestType: "delete",
        });

        if (error != null) {
            return Loader.hide();
        }

        Loader.hide();

        dispatcher(deleteItem({ type: "mine", item: job }));

        navigate(-1);
    };

    useEffect(() => {
        fetchOtherProfile();

        if (isItMe) {
            fetchApplicantsCount();
        }
    }, []);

    async function checkChatroomExists() {
        Loader.show();

        const [chatroomData, error] = await fetch({
            route: `chats/auth/jobs/${job.uuid}`,
            requestType: "get",
            cacheKey: `chatroomExists${job.uuid}`,
        });

        if (error != null) {
            console.log(error);
            return Loader.hide();
        }

        Loader.hide();

        if (chatroomData != null) {
            setChatroomExists(true);
        }
    }

    async function fetchApplicantsCount() {
        Loader.show();

        const [applicantsData, applicantsError] = await fetch({
            route: `chats/auth/get/applicants/job/${job.uuid}/count`,
            requestType: "get",
        });

        Loader.hide();

        if (applicantsError != null) {
            return showToast(applicantsError);
        }

        setApplicantsCount(applicantsData.res.count);
    }

    const fetchOtherProfile = async () => {
        Loader.show();
        dispatcher(setProgress(50));

        const [result, error] = await fetch({
            route: `jobs/${job.uuid}`,
            requestType: "get",
            cacheKey: job.uuid,
        });

        dispatcher(setProgress(100));
        Loader.hide();

        if (error != null) {
            return;
        }

        setOtherProfile(result.res.profile);

        dispatcher(updateItem({
            type: "mine", item: result.res,
        }));
    };

    const discussForApply = async () => {
        let chatroom;

        Loader.show();
        dispatcher(setProgress(50));
        const [chatroomData, error] = await fetch({
            route: `chats/auth/chatrooms/${otherProfile.uuid}`,
            requestType: "post",
        });

        if (error != null) {
            Loader.hide();
            setProgress(100);
            return;
        }

        setProgress(75);

        chatroom = chatroomData.res;

        const [channelData, channelError] = await fetch({
            route: "chats/auth/channels",
            requestType: "post",
            body: {
                name: job.title,
                chatroomUUID: chatroom.uuid,
                jobUUID: job.uuid,
            }
        });

        dispatcher(setProgress(100));
        Loader.hide();

        if (channelError != null) {
            showToast(channelError);

            return;
        }

        const channel = channelData.res[0];

        chatroom.channels = {
            ...chatroom.channels,
            ["-1"]: { id: "-1", uuid: "-1" },
            [channel.uuid]: channel,
        };

        chatroom.otherProfile = job.profile;

        dispatcher(updateChatroom({ item: chatroom }));

        navigate(`/chatRooms/${chatroom.uuid}/channel/${channel.uuid}`, {
            state: {
                chatroomUUID: chatroom.uuid,
                job: job,
                channel: channel,
            }
        });
    };

    const onViewApplicants = async () => {
        navigate("/ApplicantsScreen", {
            state: {
                job: job,
            }
        });
    };

    function onImageClick() {
        dispatcher(Alertdialog.show({
            child: <ImageVideoCarousel items={[...job.images.map((image) =>
                <img key={image} className="w-full h-[50rem] object-contain" src={`${domain}/static/${image}`} alt="job" />
            )]} />,
            className: "bg-transparent !shadow-none",
        }));
    }

    return <main>
        <section>
            <div className="flex flex-col w-[100%]">
                <div className="mx-10">
                    <div className="mt-6 w-[100%] xl:px-32 lg:px-20 flex flex-col">
                        <div className="flex flex-row justify-between items-center w-[100%]">
                            <h1 className="text-black font-normal mb-10 text-3xl mt-12">{label} <span className="font-semibold">Job</span></h1>
                            {isItMe && <Link to="/EditJob" state={{ job: job }}>
                                <button style={{ backgroundColor: "#F2F2F2", border: "1px solid #DDDDDD" }} className="filter-icon rounded-primary h-11 w-[120px] justify-center transition-all duration-200 ease-in-out flex flex-row items-center gap-2">
                                    <EditIcon />
                                    <h1 className="flex flex-row gap-2 text-darkGray font-semibold text-md capitalize">edit</h1>
                                </button>
                            </Link>}
                        </div>
                        <div className="w-[100%] h-[1px] bg-darkGray bg-opacity-20" />

                        <h1 className="text-black font-medium mb-5 text-3xl mt-12">{job.title}</h1>
                        <p className="text-darkGray opacity-50 w-[80%]">{job.fullDescription}</p>
                        <h1 className="text-darkGray font-semibold mt-5">Images:</h1>

                        <div className="2xl:w-[60%] w-[65%] max-md:w-[75%] gap-4 flex flex-row flex-wrap justify-start pt-3">
                            {job.videos.map((video) => <VideoContainer className="w-[130px] h-[130px]" key={video} url={video} />)}
                            {job.images.map((image) =>
                                <img key={image} onClick={() => onImageClick(job.images, job.videos)} className="cursor-pointer rounded-primary w-[130px] h-[130px] object-cover" src={`${domain}/static/${image}`} alt="job" />
                            )}
                        </div>

                        <div className="w-[100%] h-[8px] bg-darkGray bg-opacity-5 my-10" />

                        <div style={{ boxShadow: "6px 6px 8px 6px rgba(0,0,0,0.02)" }} className="bg-white border px-4 py-4 items-start mb-10 rounded-primary flex flex-col w-[500px] border-card-border">
                            {otherProfile != null && <div className="flex flex-row items-start justify-between w-[100%] gap-4">
                                <img className="w-[18%] object-cover aspect-square rounded-[50%]" src={`${domain}/static/${otherProfile.profileImage}`} alt="profile" />
                                <div className="flex-grow flex flex-col gap-1 items-start justify-around">
                                    <h1 className="text-black font-semibold text-xl line-clamp-2">{`${otherProfile.firstName} ${otherProfile.lastName}`}</h1>
                                    <div className="flex flex-row w-[100%] justify-start items-start gap-2">
                                        <Location className="h-6 w-6 mt-[1px]" height="100%" width="100%" />
                                        <h1 className="text-lightGray text-sm mr-5 line-clamp-2 text-ellipsis">{`${otherProfile.location}, ${otherProfile.landmark}, ${otherProfile.state}`}</h1>
                                    </div>
                                    <div className="flex flex-row justify-start w-[100%] items-start gap-2">
                                        <Calender className="h-4 w-6 mt-[1px]" height="100%" width="100%" />
                                        <h1 className="text-lightGray text-sm mr-5 line-clamp-1">{dateFormat(job.date, "d mmm, yyyy")}</h1>
                                    </div>
                                </div>
                            </div>}
                            <h1 className="text-black font-semibold text-md capitalize mt-2">job location:</h1>
                            <div className="flex flex-row w-[100%] justify-start items-center gap-2 mb-5">
                                <Location className="h-6 w-6 mt-[1px]" height="100%" width="100%" />
                                <h1 className="text-lightGray text-sm mr-5 line-clamp-2 text-ellipsis">{`${job.address}, ${job.city}, ${job.state}`}</h1>
                            </div>

                            {isItMe && <div className="flex flex-row w-[100%] gap-3 h-11">
                                <OutlinedButton onClicked={onDelete} label="Delete" extracss="flex-1 text-black bg-darkGray bg-opacity-20 hover:border-primary hover:bg-opacity-10 border-none" />
                                <OutlinedButton onClicked={onViewApplicants} extracss="flex-1 text-white primary-gradient" label={
                                    <div className="flex gap-2 justify-center items-center">
                                        <h1>View Applicants</h1>
                                        {applicantsCount != null && <div className="bg-red h-4 w-4 rounded-[50%] aspect-square grid place-content-center text-xs font-semibold">
                                            {applicantsCount}
                                        </div>}
                                    </div>
                                } />
                            </div>}
                            {!isItMe && <div className="flex flex-row w-[100%] gap-3 h-11">
                                <OutlinedButton onClicked={() => discussForApply()} label={chatroomExists ? "Go to Chatroom" : "Click to Connect"} extracss="flex-1 text-white primary-gradient" />
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>;
};

export default JobInfo;