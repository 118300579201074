import { ErrorMessage, FormikProvider, useFormik } from "formik";
import { useState } from "react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { ReactComponent as Cross } from "../../../assets/icons/dashboard/cross.svg";
import fetch from "../../../axios/manager";
import SearchListing from "../../../dialogs/alertdialog/searchlisting";
import * as Loader from "../../../dialogs/loader/loaderop";
import showToast from "../../../dialogs/loader/toast";
import Button from "../../../global/components/buttton";
import TextField from "../../../global/components/textfield";
import VideoContainer from "../../../global/components/videocontainer";
import { toFormData } from "../../../global/methods/toformdata";
import * as Alertdialog from "../../../redux/slices/alertdialog";
import { addItem } from "../../../redux/slices/joblistings";
import UploadContainer from "../components/uploadcontainer";

const AddService = () => {
    const dispatcher = useDispatch();
    const navigate = useNavigate();
    const initialData = {
        "title": "",
        "listing": "",
        "fullDescription": "",
    };

    const userSlice = useSelector(state => state.userSlice);

    const [images, setImages] = useState(null);
    const [videos, setVideos] = useState(null);
    const [blobs, setBlobs] = useState(null);
    const [videoBlobs, setVideoBlobs] = useState(null);
    const [listing, setListing] = useState(null);

    const onSubmit = async (values) => {
        if (listing == null || images == null) return;
        Loader.show();

        const body = {
            ...values,
            listingUUID: listing.uuid,
            images: blobs,
        };

        const [response, error] = await fetch({
            requestType: "post",
            route: "services/auth/",
            body: toFormData(body),
        });

        if (error != null) {
            showToast(error);
            return Loader.hide();
        }

        Loader.hide();

        dispatcher(addItem({ type: "services", item: response.res }));

        navigate(-1);
    };

    const formSchema = Yup.object().shape({
        title: Yup.string()
            .required('Required'),
        listing: Yup.string()
            .required('Required'),
        fullDescription: Yup.string()
            .required('Required'),
    });

    const formik = useFormik({
        initialValues: initialData,
        onSubmit: onSubmit,
        validationSchema: formSchema,
        validateOnBlur: false,
        validateOnChange: false,
    });

    const onUpload = () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = `image/* ${userSlice.user.videoUpload > 0 ? ", video/*" : ''}`;
        input.multiple = true;

        input.onchange = (e) => {
            decodeImages(e.target.files);
        };

        input.click();
    };

    const decodeImages = (files) => {
        const myImages = [];
        const myImageBlobs = [];
        const myVideos = [];
        const myVideoBlobs = [];
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const type = file.type;

            if (type.startsWith('image/')) {
                myImages.push(URL.createObjectURL(file));
                myImageBlobs.push(file);
            }
            else if (type.startsWith('video/')) {
                myVideos.push(URL.createObjectURL(file));
                myVideoBlobs.push(file);
            }
        }

        setImages([
            ...images ?? [],
            ...myImages,
        ]);

        setVideos([
            ...videos ?? [],
            ...myVideos,
        ]);

        setBlobs([
            ...blobs ?? [],
            ...myImageBlobs,
        ]);

        setVideoBlobs([
            ...videoBlobs ?? [],
            ...myVideoBlobs,
        ]);
    };

    const removeImage = (indexToRemove) => {
        const updatedImages = images.filter((_, index) => index !== indexToRemove);
        const updatedBlobs = blobs.filter((_, index) => index !== indexToRemove);
        setImages(updatedImages);
        setBlobs(updatedBlobs);
    };

    const removeVideo = (indexToRemove) => {
        const updatedVideos = videos.filter((_, index) => index !== indexToRemove);
        const updatedBlobs = videoBlobs.filter((_, index) => index !== indexToRemove);
        setVideos(updatedVideos);
        setVideoBlobs(updatedBlobs);
    };

    const searchListingClicked = () => {
        dispatcher(Alertdialog.show({
            child: <SearchListing onSubmit={(item) => {
                setListing(item);
                formik.setFieldValue("listing", item.type);
            }} />
        }));
    };

    return <main>
        <section>
            <div className="flex flex-col w-[100%]">
                <div className="flex flex-col mx-10 items-center justify-start">
                    <div className="mt-6 w-[100%] xl:px-32 lg:px-20">
                        <h1 className="text-black font-normal mb-10 text-3xl mt-12">{"+ Add "}<span className="font-semibold">Service</span></h1>
                        <FormikProvider value={formik}>
                            <form onSubmit={formik.handleSubmit}>
                                <div className="bg-white flex flex-col gap-8 rounded-primary max-md:w-[100%] w-[600px] border-card-border border p-5" style={{ boxShadow: "0px 6px 8px 0px rgba(0,0,0,0.05)" }}>
                                    <div className="grid grid-cols-1 gap-3">
                                        <TextField error={formik.errors.title != null && formik.touched} size="h-12" type="text" name="title" placeholder="Title" />
                                        <TextField readOnly onClick={() => searchListingClicked()} error={formik.errors.listing != null && formik.touched} size="h-12" type="text" name="listing" placeholder="Service Type" />

                                        <div className="flex flex-col">
                                            <textarea onChange={formik.handleChange} value={formik.values.fullDescription}
                                                className={`${(formik.errors.fullDescription != null && formik.touched) ? "border-red" : "border-textfield-stroke"} bg-textfield-back resize-y border w-[100%] rounded-[10px] outline-none px-5 py-3`}
                                                name="fullDescription" placeholder="Description" />

                                            <ErrorMessage name="fullDescription" className="text-xs text-red" component="p" />
                                        </div>

                                        <div className="flex flex-col">
                                            <label>{`Service ${userSlice.user.videoUpload > 0 ? "Media" : "Images"}`}</label>
                                            <p className="text-xs">{`You can upload up to ${userSlice.user.imageUpload} images ${userSlice.user.videoUpload > 0 ? `and ${userSlice.user.videoUpload} ${userSlice.user.videoUpload == 1 ? "video" : "videos"}` : ""} as per your subscription plan.`}</p>
                                            <UploadContainer error={(images == null || images.length === 0) && formik.isSubmitting} onClick={() => onUpload()} extraCss="flex-grow" />
                                        </div>
                                    </div>
                                    {((images != null && images.length !== 0) || (videos != null && videos.length !== 0)) && <div className="flex flex-row flex-wrap gap-3 items-center justify-center">
                                        {(videos ?? []).map((video, index) => {
                                            return (
                                                <VideoContainer className="h-24 w-24" onRemove={() => removeVideo(index)} showRemove key={index} file={video} />
                                            );
                                        })}
                                        {(images ?? []).map((image, index) => {
                                            return (
                                                <div className="h-24 w-24 relative" key={image}>
                                                    <div onClick={() => removeImage(index)} className="bg-white absolute right-1 top-1 cursor-pointer rounded-[50%] w-4 h-4 p-[1px] flex justify-center items-center">
                                                        <Cross />
                                                    </div>
                                                    <img src={image} className="object-cover aspect-square rounded-primary" alt="picked" height="100%" width="100%" />
                                                </div>
                                            );
                                        })}
                                    </div>}
                                    <Button child="Publish" extraCss="self-center h-11 w-[25%]" />
                                </div>
                            </form>
                        </FormikProvider>
                    </div>
                </div>
            </div>
        </section>
    </main>;
};

export default AddService;