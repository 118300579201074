import React from 'react';
import { Link } from "react-router-dom";

import { ReactComponent as FacebookIcon } from '../../assets/icons/homescreen/facebbok-icon.svg';
import { ReactComponent as GoogleIcon } from '../../assets/icons/homescreen/google-icon.svg';
import { ReactComponent as LinkdinIcon } from '../../assets/icons/homescreen/linkdin-icon.svg';
import locationIcon from '../../assets/icons/homescreen/location-icon.svg';
import Logo from '../../assets/icons/homescreen/logo.svg';
import PhoneIcon from '../../assets/icons/homescreen/phone-icon.svg';
import { ReactComponent as TwitterIcon } from '../../assets/icons/homescreen/twitter-icon.svg';

export default function Footer() {
    return (
        <>
            <footer className='bg-white'>
                <div className="container mx-auto xl:pt-[120px] md:pt-[80px] xl:pb-[72px] md:pb-[42px] py-[50px]">
                    <div className="grid lg:grid-cols-4 sm:grid-cols-2 grid-cols-1  sm:gap-[120px] gap-[30px]">
                        <div className="">
                            <div className="flex flex-shrink-0 items-center mb-5">
                                <Link to={"/"}>
                                    <img loading='lazy' className="h-[54px] w-auto" src={Logo} alt="OnceUSA Logo" />
                                </Link>
                            </div>
                            <p className='text-sm font-normal text-darkGray'>The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using.</p>
                            <div className="flex justify-between items-center my-4 max-w-[80%]">
                                <Link to={'/'} className='opacity-100 hover:opacity-60'><TwitterIcon className='footer-social-icon' /></Link>
                                <span className='bg-[#3F3F3F] h-[20px] w-[1px] block'></span>
                                <Link to={'/'} className='opacity-100 hover:opacity-60'><FacebookIcon className='footer-social-icon' /></Link>
                                <span className='bg-[#3F3F3F] h-[20px] w-[1px] block'></span>
                                <Link to={'/'} className='opacity-100 hover:opacity-60'><LinkdinIcon className='footer-social-icon' /></Link>
                                <span className='bg-[#3F3F3F] h-[20px] w-[1px] block'></span>
                                <Link to={'/'} className='opacity-100 hover:opacity-60'><GoogleIcon className='footer-social-icon' /></Link>
                            </div>
                        </div>

                        <div className='flex lg:justify-end sm:justify-start line-left'>
                            <div className='xl:w-2/3'>
                                <h4 className='text-lg text-black capitalize font-bold mb-2'>Company</h4>
                                <ul className='pl-0'>
                                    <li className='py-[3px] list-disc list-inside'><Link to={'/'} className="hover:opacity-80 text-darkGray opacity-100 capitalize text-sm font-normal active" aria-current="page">Home</Link></li>
                                    <li className='py-[3px] list-disc list-inside'><Link to='/#categories' className="hover:opacity-80 text-darkGray opacity-100 capitalize text-sm font-normal active" aria-current="page">Categories</Link></li>
                                    <li className='py-[3px] list-disc list-inside'><Link to='/#reviews' className="hover:opacity-80 text-darkGray opacity-100 capitalize text-sm font-normal active" aria-current="page">Review</Link></li>
                                    <li className='py-[3px] list-disc list-inside'><Link to='/#features' className="hover:opacity-80 text-darkGray opacity-100 capitalize text-sm font-normal active" aria-current="page">Features</Link></li>
                                    <li className='py-[3px] list-disc list-inside'><Link to='/#faq' className="hover:opacity-80 text-darkGray opacity-100 capitalize text-sm font-normal active" aria-current="page">FAQ’s</Link></li>
                                </ul>
                            </div>
                        </div>

                        <div className='flex lg:justify-end sm:justify-start line-left line-left-lg-none'>
                            <div className='xl:w-2/3'>
                                <h4 className='text-lg text-black capitalize font-bold mb-2'>All Services</h4>
                                <ul className='pl-0'>
                                    <li className='py-[3px] list-disc list-inside'><Link to={'/'} className="hover:opacity-80 text-darkGray opacity-100 capitalize text-sm font-normal active" aria-current="page">Home cleaning</Link></li>
                                    <li className='py-[3px] list-disc list-inside'><Link to={'/'} className="hover:opacity-80 text-darkGray opacity-100 capitalize text-sm font-normal active" aria-current="page">Home Painting</Link></li>
                                    <li className='py-[3px] list-disc list-inside'><Link to={'/'} className="hover:opacity-80 text-darkGray opacity-100 capitalize text-sm font-normal active" aria-current="page">Pest Control</Link></li>
                                    <li className='py-[3px] list-disc list-inside'><Link to={'/'} className="hover:opacity-80 text-darkGray opacity-100 capitalize text-sm font-normal active" aria-current="page">Marble Polish</Link></li>
                                    <li className='py-[3px] list-disc list-inside'><Link to={'/'} className="hover:opacity-80 text-darkGray opacity-100 capitalize text-sm font-normal active" aria-current="page">Sanitizing</Link></li>
                                    <li className='py-[3px] list-disc list-inside'><Link to={'/'} className="hover:opacity-80 text-darkGray opacity-100 capitalize text-sm font-normal active" aria-current="page">Integrated Pest</Link></li>
                                </ul>
                            </div>
                        </div>

                        <div className='flex sm:justify-end line-left'>
                            <div className='xl:w-2/3'>
                                <h4 className='text-lg text-black capitalize font-bold mb-2'>Connect with Us</h4>
                                <ul className='pl-0'>
                                    <li className='py-2'><Link to={'tel:98801 00910'} className="flex items-start gap-2 text-darkGray capitalize text-sm font-normal active" aria-current="page"><img className='brightness-0' src={PhoneIcon} alt='phone icon' /><span>+1 12345 67890</span></Link></li>
                                    <li className='py-2'><Link to={'/'} className="flex items-start gap-2 text-darkGray capitalize text-sm font-normal active" aria-current="page"><img className='brightness-0' src={locationIcon} alt='location icon' /><span>Lorem ipsum dolor sit amet consectetur adipisicing elit.</span></Link></li>
                                </ul>

                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}