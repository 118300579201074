import React from "react";
import VideoThumbnail from 'react-video-thumbnail';

import Play from "../../assets/icons/chatrooms/play.svg";
import { ReactComponent as Cross } from "../../assets/icons/dashboard/cross.svg";
import { BUNNY_LIBRARY_ID, BUNNY_PULLZONE, BUNNY_VIDEO_VIEW_HOST } from "../../global/variables/env";

export default function VideoContainer({ url, className, showRemove = false, file, onRemove }) {
    return <div className="relative cursor-pointer" rel="noreferrer">
        {url != null ?
            <a href={`${BUNNY_VIDEO_VIEW_HOST}/play/${BUNNY_LIBRARY_ID}/${url}`} target="_blank" rel="noreferrer">
                <img key={url} className={`cursor-pointer rounded-primary ${className} object-cover`}
                    src={`${BUNNY_PULLZONE}/${url}/thumbnail.jpg`} alt="job" />
            </a>
            : <div className="h-24 w-24 aspect-square rounded-primary overflow-hidden" key={file}>
                <VideoThumbnail
                    videoUrl={file}
                />
            </div>}

        {showRemove && <div onClick={onRemove} className="bg-white z-10 absolute right-1 top-1 cursor-pointer rounded-[50%] w-4 h-4 p-[1px] flex justify-center items-center">
            <Cross />
        </div>}

        <div className="w-full h-full absolute top-0 grid place-content-center">
            <div className="h-5 w-5 aspect-square">
                <img src={Play} alt="icon" />
            </div>
        </div>
    </div>;
}