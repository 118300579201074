import { FormikProvider, useFormik } from "formik";
import React from "react";
import * as Yup from "yup";

import CallIcon from '../../assets/icons/homescreen/call-icon.svg';
import EmailIcon from '../../assets/icons/homescreen/envolpe-icon.svg';
import MapIcon from '../../assets/icons/homescreen/map-icon.svg';
import BannerBg from '../../assets/images/contact-bg.png';
import Button from "../../global/components/buttton";
import TextField from "../../global/components/textfield";
import Footer from "../components/footer";

const ContactUs = () => {
    const initialData = {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        message: "",
    };

    const onSubmit = async (values, { resetForm }) => {
        try {
            console.log("Form Data Submitted:", values);

            // If needed, make an API call here
            // Example:
            // const response = await fetch('/api/contact', {
            //     method: 'POST',
            //     headers: { 'Content-Type': 'application/json' },
            //     body: JSON.stringify(values),
            // });
            // const result = await response.json();
            // console.log("API Response:", result);

            alert("Form submitted successfully!");
            resetForm(); // Reset the form after successful submission
        } catch (error) {
            console.error("Error submitting form:", error);
            alert("Failed to submit form. Please try again.");
        }
    };

    const formSchema = Yup.object().shape({
        firstName: Yup.string()
            .required('Required'),
        lastName: Yup.string()
            .required('Required'),
        city: Yup.string()
            .required('Required'),
        email: Yup.string()
            .required('Required'),
        phone: Yup.string()
            .required('Required'),
        message: Yup.string()
            .required('Required'),
    });


    const formik = useFormik({
        initialValues: initialData,
        onSubmit: onSubmit,
        validationSchema: formSchema,
        validateOnBlur: false,
        validateOnChange: false,
    });


    return <div className="h-[100dvh] flex flex-col">
        <main>
            <section className="min-h-[420px] flex items-center justify-center" style={{ backgroundImage: `url(${BannerBg})` }} >
                <div className="container mx-auto">
                    <h1 className='text-center md:text-6xl text-4xl uppercase font-bold leading-normal text-white'>contact us</h1>
                </div>
            </section>

            <section className="bg-white md:py-28 py-14">
                <div className="container mx-auto">
                    <div className="grid grid-cols-3 max-md:grid-cols-1 gap-[50px] max-md:gap-x-0">
                        <div className="col flex flex-col gap-[20px] justify-center w-full">
                            <div className="flex items-center gap-[15px]">
                                <div className="flex items-center justify-center shadow-[0_5px_15px_0px_rgba(0,0,0,0.06)] max-w-[100px] min-w-[100px] min-h-[100px] max-md:min-w-[60px] max-md:min-h-[80px] max-sm:min-w-[50px] max-sm:min-h-[50px] max-sm:max-w-[60px] rounded-[8px] p-3 text-center h-full bg-white border-Gainsboro border-[1px]">
                                    <img src={CallIcon} alt="Icon" className="w-full max-md:w-[80%]" />
                                </div>
                                <div>
                                    <h2 className="text-lg font-normal text-black">Phone</h2>
                                    <a href="tel:+1 12345 67890" className="text-[#707070] text-base font-normal">+1 12345 67890</a>
                                </div>
                            </div>
                            <div className="flex items-center gap-[15px]">
                                <div className="flex items-center justify-center shadow-[0_5px_15px_0px_rgba(0,0,0,0.06)] max-w-[100px] min-w-[100px] min-h-[100px] max-md:min-w-[60px] max-md:min-h-[80px] max-sm:min-w-[50px] max-sm:min-h-[50px] max-sm:max-w-[60px] rounded-[8px] p-3 text-center h-full bg-white border-Gainsboro border-[1px]">
                                    <img src={EmailIcon} alt="Icon" className="w-full max-md:w-[80%]" />
                                </div>
                                <div>
                                    <h2 className="text-lg font-normal text-black">Email</h2>
                                    <a href="mailto:infoonce@gmail.com" className="text-[#707070] text-base font-normal">infoonce@gmail.com</a>
                                </div>
                            </div>
                            <div className="flex items-center gap-[15px]">
                                <div className="flex items-center justify-center shadow-[0_5px_15px_0px_rgba(0,0,0,0.06)] max-w-[100px] min-w-[100px] min-h-[100px] max-md:min-w-[60px] max-md:min-h-[80px] max-sm:min-w-[50px] max-sm:min-h-[50px] max-sm:max-w-[60px] rounded-[8px] p-3 text-center h-full bg-white border-Gainsboro border-[1px]">
                                    <img src={MapIcon} alt="Icon" className="w-full max-md:w-[80%]" />
                                </div>
                                <div>
                                    <h2 className="text-lg font-normal text-black">Location</h2>
                                    <span className="text-[#707070] text-base font-normal max-w-[280px] ml-0 inline-block">Lorem ipsum dolor sit amet consectetur
                                        adipisicing elit.</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-span-2">
                            <FormikProvider value={formik}>
                                <form onSubmit={formik.handleSubmit}>
                                    <div className="bg-white flex flex-col gap-8 rounded-primary border-card-border border p-10 max-sm:p-5" style={{ boxShadow: "0px 6px 8px 0px rgba(0,0,0,0.05)" }}>
                                        <div className="grid grid-cols-2 max-md:grid-cols-1 gap-3">
                                            <div>
                                                <label htmlFor="firstName">First Name</label>
                                                <TextField error={formik.errors.firstName != null && formik.touched} size="h-12" extraCss={'mb-2'} type="text" name="firstName" placeholder="Enter Your First Name" />
                                            </div>

                                            <div>
                                                <label htmlFor="lastName">First Name</label>
                                                <TextField error={formik.errors.lastName != null && formik.touched} size="h-12" extraCss={'mb-2'} type="text" name="lastName" placeholder="Enter Your Last Name" />
                                            </div>

                                            <div>
                                                <label htmlFor="email">Email</label>
                                                <TextField error={formik.errors.email != null && formik.touched} size="h-12" extraCss={'mb-2'} type="email" name="email" placeholder="Enter Your Email" />
                                            </div>

                                            <div>
                                                <label htmlFor="phone">Phone</label>
                                                <TextField error={formik.errors.phone != null && formik.touched} size="h-12" extraCss={'mb-2'} type="tel" name="phone" placeholder="Enter Your Phone No." />
                                            </div>

                                            <div>
                                                <label htmlFor="message">Message</label>
                                                <textarea onChange={formik.handleChange} value={formik.values.message}
                                                    className={`${(formik.errors.message != null && formik.touched) ? "border-red" : "border-textfield-stroke"} bg-textfield-back resize-none flex-grow border w-[100%] rounded-[10px] outline-none px-5 py-3 col-span-2`}
                                                    name="message" placeholder="Enter Your Message" />
                                            </div>
                                        </div>
                                        <Button type="submit" child="submit" extraCss="self-start h-11 w-[25%] uppercase" />
                                    </div>
                                </form>
                            </FormikProvider>
                        </div>
                    </div>
                </div>
            </section>
        </main>

        {/* <div className="flex flex-auto items-center justify-center text-center px-4 flex-col sm:flex-row h-full">
            <h1 className="text-2xl sm:text-3xl font-extrabold text-slate-900 tracking-tight sm:pr-6 sm:mr-6 sm:border-r sm:border-slate-900/10 sm:dark:border-slate-300/10 dark:text-slate-200">Coming soon</h1>
            <h2 className="mt-2 text-lg text-slate-700 dark:text-slate-400 sm:mt-0">This feature is coming soon</h2>
        </div> */}
        <Footer />
    </div>;

};

export default ContactUs;