import React, { createContext, useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useParams } from "react-router-dom";
import { io } from "socket.io-client";

import fetch from "../axios/manager";
import * as JwtManager from "../cache/jwt";
import showToast from "../dialogs/loader/toast";
import * as chatRedux from "../redux/slices/chatrooms";
import * as notificationRedux from "../redux/slices/notifications";

const SocketContext = createContext(null);

export const useSocket = () => useContext(SocketContext);

export default function SocketProvider() {
    const dispatcher = useDispatch();

    const userSlice = useSelector(state => state.userSlice);
    const chatroomSlice = useSelector(state => state.chatroomsSlice);
    const notificationsSlice = useSelector(state => state.notificationsSlice);

    const profile = userSlice.profile;

    const notificationsRef = useRef(notificationsSlice.notificationData);
    const chatroomRef = useRef(chatroomSlice);

    const { chatroomUUID, channelUUID } = useParams();

    const [openedChatroomUUID, setOpenedChatroomUUID] = useState(null);
    const [openedChannelUUID, setOpenedChannelUUID] = useState(null);
    const [socket, setSocket] = useState(null);

    useEffect(() => {
        setOpenedChatroomUUID(chatroomUUID);
        setOpenedChannelUUID(channelUUID);
    }, [chatroomUUID, channelUUID]);

    useEffect(() => {
        notificationsRef.current = notificationsSlice.notificationData;
    }, [notificationsSlice.notificationData]);

    useEffect(() => {
        chatroomRef.current = chatroomSlice;
    }, [chatroomSlice]);

    useEffect(() => {
        let socketInstance;

        async function socketInit() {
            const token = await JwtManager.getToken();

            if (token == null) return;

            socketInstance = io("https://chat.onceusa.com", {
                auth: {
                    token: `Bearer ${token}`,
                },
                withCredentials: true,
                transports: ["websocket"],
                reconnection: true,
            });

            socketInstance.onAny((event) => {
                console.log(event);
            });

            socketInstance.on("connect", () => {
                console.log("Socket connected");
            });

            socketInstance.on("disconnect", () => {
                console.log("Socket disconnected");
            });

            setSocket(socketInstance);

            socketInstance.on("new_message", async (val) => {
                const data = JSON.parse(val);
                const payload = data.payload;

                const { chatroomUUID, chatUUID, channelUUID, updateEstimate, markReadAll, readStatus, profileUUID } = payload;

                if (chatUUID != null) {
                    let chat;

                    if (payload.chat == null) {
                        const [chatData, chatError] = await fetch({
                            route: `chats/auth/chatrooms/${chatroomUUID}/chats/${chatUUID}`,
                            requestType: "get",
                        });

                        if (chatError != null) {
                            showToast(chatError);
                            return;
                        }

                        if (chatData.res.estimate != null) {
                            chatData.res.chat.estimate = data.res.estimate;
                        }

                        const chatroom = chatroomRef.current[chatroomUUID];

                        if (chatroom != null) {
                            chat = chatData.res;

                            if (updateEstimate == null) {
                                dispatcher(chatRedux.addChat({
                                    chatroomUUID: chatroomUUID,
                                    chat: chat,
                                    channelUUID: channelUUID,
                                    last: true,
                                }));
                            }
                        }
                    }
                    else {
                        chat = payload.chat;

                        if (updateEstimate == null) {
                            dispatcher(chatRedux.addChat({
                                chatroomUUID: chatroomUUID,
                                chat: chat,
                                channelUUID: channelUUID,
                                last: true,
                            }));
                        }
                    }

                    if (updateEstimate != null && chat != null) {
                        dispatcher(chatRedux.updateChat({
                            chat: chat,
                            chatroomUUID: chatroomUUID,
                            channelUUID: channelUUID,
                        }));

                        return;
                    }

                    let readStatus = 1;

                    if (chatroomUUID == openedChatroomUUID && channelUUID == openedChannelUUID) {
                        readStatus = 2;
                    }


                    const [, readStatusError] = await fetch({
                        route: "chats/auth/chats/updateAllReadStatus",
                        requestType: "put",
                        body: {
                            chatroomUUID: chatroomUUID,
                            channelUUID: channelUUID,
                            readStatus: readStatus,
                        }
                    });

                    socketInstance.emit("send_message", JSON.stringify({
                        otherProfileUUID: profileUUID,
                        payload: {
                            chatroomUUID: chatroomUUID,
                            channelUUID: channelUUID,
                            readStatus: readStatus,
                            markReadAll: "true",
                        }
                    }));

                    if (readStatusError != null) {
                        showToast(readStatusError);
                        return;
                    }

                    if (readStatus == 2) {
                        return;
                    }

                    const notification = (notificationsRef.current[`${chatroomUUID}~${channelUUID}`]) ?? 0;

                    if (notification == 0) {
                        dispatcher(notificationRedux.incrementNotificationCount());
                    }

                    dispatcher(notificationRedux.setNotifications({
                        chatroomUUID: chatroomUUID,
                        channelUUID: channelUUID,
                        count: notification + 1,
                    }));
                }
                else if (markReadAll == "true") {
                    dispatcher(chatRedux.markReadAll({
                        chatroomUUID: chatroomUUID,
                        channelUUID: channelUUID,
                        readStatus: readStatus,
                        mine: true,
                        profileId: profile.id,
                    }));
                }
            });
        }

        socketInit();

        return () => {
            if (socketInstance) {
                socketInstance.disconnect();
            }
        };
    }, []);

    return <SocketContext.Provider value={socket}>
        <Outlet />
    </SocketContext.Provider>;
}