import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import JobItem from "./job-item";
import { ReactComponent as FilterIcon } from "../../../assets/icons/dashboard/filter-icon.svg";
import fetch from "../../../axios/manager";
import LimitByDistance from "../../../dialogs/alertdialog/limit_by_distance";
import * as Loader from "../../../dialogs/loader/loaderop";
import showToast from "../../../dialogs/loader/toast";
import PaginatedScrollView, { PaginatedScrollViewDistance } from "../../../global/components/paginatedscrollview";
import TabBar from "../../../global/components/tabbar";
import * as AlertDialog from "../../../redux/slices/alertdialog";
import { addInnerItem, addItem } from "../../../redux/slices/joblistings";
import { setProgress } from "../../../redux/slices/toploadingbar";
import OutlinedButton from "../../homepage/components/outlinedbutton";
import CompletedGigItem from "../../profile/components/completedGigItem";

const JobListingComponent = () => {
    const userSlice = useSelector((state) => state.userSlice);
    const user = userSlice.user;
    const profile = userSlice.profile;
    const isClient = user.userType !== "provider";

    const tabItems = ['New', 'Progress', 'Completed'];
    const newJobs = useSelector((state) => state.jobListingSlice.new);
    const myJobs = useSelector((state) => Object.values(state.jobListingSlice.mine));
    const progressJobs = useSelector((state) => Object.values(state.jobListingSlice.progress));
    const completedGigs = useSelector((state) => Object.values(state.jobListingSlice.completed));

    const [distance, setDistance] = useState(-1);
    const [current, setCurrent] = useState(0);
    const dispatcher = useDispatch();

    const onDistanceClicked = () => {
        dispatcher(AlertDialog.show({ child: <LimitByDistance initialDistance={distance} onChange={(value) => setDistance(value)} /> }));
    };

    const fetchMyJobs = async (offset) => {
        if (offset === 0 && myJobs.length !== 0) {
            return myJobs;
        }

        dispatcher(setProgress(50));
        Loader.show();

        const [result, error] = await fetch({
            route: "jobs/auth/all",
            requestType: "get",
            cacheKey: `jobs-new-distance--1-${offset}`,
            params: {
                "limit": 10,
                "offset": offset,
                "sort": "DESC",
            },
        });

        if (error != null) {
            onError(error);
            return [];
        }

        const jobs = result.res;

        for (let i = 0; i < jobs.length; i++) {
            dispatcher(addItem({ type: "mine", item: jobs[i] }));
        }

        dispatcher(setProgress(100));
        Loader.hide();

        return jobs;
    };

    const fetchNewJobs = async (offset) => {
        if (offset === 0 && newJobs[distance] != null && newJobs[distance].length !== 0) {
            return Object.values(newJobs[distance]);
        }

        dispatcher(setProgress(50));
        Loader.show();

        const [result, error] = await fetch({
            route: `jobs/auth/find/all/${distance}`,
            requestType: "get",
            cacheKey: `jobs-new-distance-${distance}-${offset}`,
            params: {
                "limit": 10,
                "offset": offset,
                "sort": "DESC",
            },
        });

        if (error != null) {
            onError(error);
            return [];
        }

        const jobs = result.res;

        for (let i = 0; i < jobs.length; i++) {
            dispatcher(addInnerItem({ distance: distance, type: "new", item: jobs[i] }));
        }

        dispatcher(setProgress(100));
        Loader.hide();

        return jobs;
    };

    const fetchProgressJobs = async (offset) => {
        if (offset === 0 && progressJobs.length !== 0) {
            return progressJobs;
        }

        dispatcher(setProgress(50));
        Loader.show();

        const [result, error] = await fetch({
            route: "/completedGigs/auth/progress/all",
            requestType: "get",
            cacheKey: `jobs_progress-${offset}`,
            params: {
                "limit": 10,
                "offset": offset,
                "sort": "DESC",
            },
        });

        if (error != null) {
            onError(error);
            return [];
        }

        const jobs = result.res;

        for (let i = 0; i < jobs.length; i++) {
            dispatcher(addItem({ type: "progress", item: jobs[i] }));
        }

        dispatcher(setProgress(100));
        Loader.hide();

        return jobs;
    };

    const fetchCompletedJobs = async (offset) => {
        if (offset === 0 && completedGigs.length !== 0) {
            return completedGigs;
        }

        dispatcher(setProgress(50));
        Loader.show();

        const [result, error] = await fetch({
            route: "completedGigs/auth/all",
            requestType: "get",
            cacheKey: `myCompletedGigs-${offset}`,
            params: {
                "limit": 10,
                "offset": offset,
                "sort": "DESC",
            },
        });

        if (error != null) {
            onError(error);
            return [];
        }

        const jobs = result.res;
        const type = user.userType;

        for (let i = 0; i < jobs.length; i++) {

            const item = jobs[i];

            const clientProfile = type == "provider" ? item.clientProfile : profile;
            const providerProfile = type == "provider" ? profile : item.providerProfile;

            const clientReview = item.clientReview != null ? {
                ...item.clientReview,
                profile: clientProfile,
            } : null;
            const providerReview = item.providerReview != null ? {
                ...item.providerReview,
                profile: providerProfile,
            } : null;

            dispatcher(addItem({
                type: "completed", item: {
                    ...item,
                    clientReview: clientReview,
                    providerReview: providerReview,
                },
            }));
        }

        dispatcher(setProgress(100));
        Loader.hide();

        return jobs;
    };

    function onError(error) {
        showToast(error);
        dispatcher(setProgress(100));
        return Loader.hide();
    }

    return <>
        <div className="w-[100%] flex flex-row my-5 max-md:flex-col items-center max-md:gap-3 justify-between max-md:items-start max-md:mx-auto">
            <TabBar current={current}
                onChange={setCurrent} innerRounded='rounded-[10px]' outerRounded='rounded-primary'
                items={tabItems} width="w-[33.33%]"
                extraCss="xl:w-[500px] lg:w-[450px] w-[400px] h-14 mr-3" />
            {isClient ?
                <div className="flex flex-row gap-3 h-14">
                    <Link className="h-[100%]" to="/AddJob">
                        <OutlinedButton onClicked={() => { }} extracss="bg-white h-[100%] border-primary text-primary font-semibold max-md:h-[50px] text-md hover:text-white hover:border-white hover:bg-primary w-[150px] text-center" label="Post A Job" />
                    </Link>
                </div>
                :
                current == 0 && <div className="flex flex-row gap-3 h-14">
                    <button onClick={() => onDistanceClicked()}
                        className={`capitalize ${distance != -1 ? "bg-primary" : "bg-[#F2F2F2]"} ${distance != -1 ? "" : "hover:bg-white hover:border-Gray85"}
                         border border-[#DDDDDD] rounded-primary w-[180px] justify-center transition-all duration-200 ease-in-out flex flex-row items-center gap-2`}>
                        {distance == -1 && <FilterIcon className="h-7 w-7 aspect-square object-contain" />}
                        <h1 className={`flex flex-row gap-2 font-semibold text-md ${distance != -1 ? "text-white" : "text-darkGray"}`}>
                            {distance != -1 ? `${distance} Miles` : "limit by distance"}</h1>
                    </button>
                </div>
            }
        </div>


        {current === 0 && !isClient && <PaginatedScrollViewDistance distance={distance} className="grid xl:grid-cols-3 lg:grid-cols-2 grid-cols-1 place-items gap-5 my-6"
            limit={10} pageRequest={fetchNewJobs} onNotFound="No new Jobs found"
            child={(item) =>
                <Link key={item.uuid} to="/JobInfo" className="w-[100%]" state={{ job: item, label: tabItems[current], isItMe: isClient }}>
                    <JobItem item={item} />
                </Link>}
        />}
        {current === 0 && isClient && <PaginatedScrollViewDistance distance={distance} className="grid xl:grid-cols-3 lg:grid-cols-2 grid-cols-1 place-items gap-5 my-6"
            limit={10} pageRequest={fetchMyJobs} onNotFound="No Jobs found"
            child={(item) =>
                <Link key={item.uuid} to="/JobInfo" className="w-[100%]" state={{ job: item, label: tabItems[current], isItMe: isClient }}>
                    <JobItem item={item} />
                </Link>}
        />}
        {current === 1 && <PaginatedScrollView className="grid xl:grid-cols-3 lg:grid-cols-2 grid-cols-1 place-items gap-5 my-6"
            limit={10} pageRequest={fetchProgressJobs} onNotFound="You don't have any Jobs in progress"
            child={(item) =>
                <Link key={item.uuid} to="/CompletedGigScreen" className="w-[100%]" state={{ gig: item, label: "Progress" }}>
                    <CompletedGigItem item={item} />
                </Link>}
        />}
        {current === 2 && <PaginatedScrollView className="grid xl:grid-cols-3 lg:grid-cols-2 grid-cols-1 place-items gap-5 my-6"
            limit={10} pageRequest={fetchCompletedJobs} onNotFound="You don't have any completed Jobs"
            child={(item) =>
                <Link key={item.uuid} to="/CompletedGigScreen" className="w-[100%]" state={{ gig: item, label: "Completed" }}>
                    <CompletedGigItem item={item} />
                </Link>}
        />}
    </>;
};

export default JobListingComponent;